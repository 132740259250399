import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useSWR from "swr";
import apiClient from "~/lib/apiClient";
import { H1 } from "~/components/ui/typography";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { Button } from "~/components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "~/components/ui/avatar";
import { Loader2, Users } from "lucide-react";
import { format, fromUnixTime } from 'date-fns';

interface Customer {
  id: string;
  name?: string;
  email: string;
  reference_id?: string;
  reference_created_at?: number;
  hostname?: string;
  subscriptions?: any[];
}

interface PaginationMeta {
  current_page: number;
  from: number;
  to: number;
  total: number;
  last_page: number;
}

interface CustomerResponse {
  data: Customer[];
  meta: PaginationMeta;
}

function Pagination({ collection }: { collection: CustomerResponse }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page')) || 1;

  const prevPage = collection.meta.current_page > 1 ? collection.meta.current_page - 1 : 1;
  const nextPage = collection.meta.current_page < collection.meta.last_page 
    ? collection.meta.current_page + 1 
    : collection.meta.last_page;

  return (
    <div className="flex items-center justify-between px-2 py-4">
      <p className="text-sm text-muted-foreground">
        Showing <span className="font-medium">{collection.meta.from}</span> to{" "}
        <span className="font-medium">{collection.meta.to}</span> of{" "}
        <span className="font-medium">{collection.meta.total}</span> customers
      </p>

      <div className="flex gap-2">
        <Button
          variant="outline"
          size="sm"
          disabled={collection.meta.current_page === 1}
          onClick={() => setSearchParams({ page: prevPage.toString() })}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          disabled={collection.meta.current_page === collection.meta.last_page}
          onClick={() => setSearchParams({ page: nextPage.toString() })}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export function avatarLetters(customer: Customer) {
  if (customer.name) {
    return customer.name.split(' ').map((n) => n[0]).join('').toUpperCase();
  }

  if (customer.email) {
    return customer.email[0].toUpperCase();
  }

  return '';
}

export default function CustomersListPage() {
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;

  const { data: collection, error, isLoading } = useSWR<CustomerResponse>(
    `/customers?page=${page}`,
    url => apiClient.get(url).then(res => res.data)
  );

  const formatDate = (timestamp?: number) => {
    if (!timestamp) return '-';
    try {
      const date = fromUnixTime(timestamp);
      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return '-';
    }
  };

  return (
    <div className="p-10 space-y-6">
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <H1>Customers</H1>
          <p className="text-sm text-muted-foreground">
            {collection?.meta.total || 0} total customers
          </p>
        </div>
      </div>

      {error && (
        <div className="flex items-center justify-center p-6 text-destructive">
          Failed to load customers
        </div>
      )}
      
      {isLoading && (
        <div className="flex items-center justify-center p-6">
          <Loader2 className="h-6 w-6 animate-spin" />
        </div>
      )}

      {collection?.data.length === 0 && (
        <div className="flex flex-col items-center justify-center py-12 text-center">
          <Users className="h-12 w-12 text-muted-foreground mb-4" />
          <h3 className="text-lg font-semibold">No Customers</h3>
          <p className="text-sm text-muted-foreground">
            Get started by importing your customers or creating one manually.
          </p>
          <Button className="mt-4" asChild>
            <Link to="/customers/import">Import Customers</Link>
          </Button>
        </div>
      )}
      
      {collection && collection.data.length > 0 && (
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Customer ID</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Name</TableHead>
                <TableHead>Subscriptions</TableHead>
                <TableHead>Created At</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {collection.data.map((customer) => (
                <TableRow key={customer.id}>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <Avatar className="h-8 w-8">
                        <AvatarImage
                          src={customer.hostname ? `https://logo-service.plandalf.dev/api/logo?url=${customer.hostname}` : undefined}
                          alt={avatarLetters(customer)}
                        />
                        <AvatarFallback>{avatarLetters(customer)}</AvatarFallback>
                      </Avatar>
                      <Link 
                        to={`/customers/${customer.id}`}
                        className="text-primary hover:underline font-medium"
                      >
                        {customer.reference_id || customer.id}
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{customer.name || '-'}</TableCell>
                  <TableCell>{customer.subscriptions?.length || 0}</TableCell>
                  <TableCell>{formatDate(customer.reference_created_at)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}

      {collection && <Pagination collection={collection} />}
    </div>
  );
}
