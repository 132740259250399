import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Form} from "@radix-ui/react-form";
import { Input } from "~/components/ui/input";
import Alert from "~/components/Alert";
import { Workflow, Handler } from "~/types";
import { WorkflowCreateRequest } from "~/types/api/workflow";
import { usePlandalf } from "@plandalf/react-plandalf-js";
import PageHeader from "~/components/PageHeader";
import HandlerForm from "~/components/workflow/HandlerForm";
import { H1, H2, H3, P } from "~/components/ui/typography";
import { Button } from "~/components/ui/button";
import { PlusIcon } from "lucide-react";
import { cn } from "~/lib/utils";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "~/components/ui/select";

const qualifierMap = {
  findButtonByText: 'button containing'
}
const eventMap = {
  click: 'is clicked',
}
const listenMap = {
  attachment: 'When ',
  custom: 'When custom event'
}

const DEFAULT_HANDLER: Handler = {
  listen: 'attachment',
  event_name: 'click',
  qualifier: 'findButtonByText',
  props: {
    text: '',
  },
};

const DEFAULT_STATE: WorkflowCreateRequest = {
  name: 'Paywall Workflow',
  lookup_key: '',
  handlers: [],
  images: []
};

export default function WorkflowCreatePage() {
  const {data: clients, error: clientsError} = useSWR(`/clients`, getFetcher<{data: Workflow['clients']}>);
  const [errors, setErrors] = useState<Record<string, [string]> | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const { plandalf } = usePlandalf();
  const [data, setData] = useState<WorkflowCreateRequest>(DEFAULT_STATE);
  const [showPreview, setShowPreview] = useState(false);

  const handleHandlerChange = (index: number, handler: Handler) => {
    const newHandlers = [...data.handlers];
    newHandlers[index] = handler;
    setData({ ...data, handlers: newHandlers });
  };

  const handleHandlerDelete = (index: number) => {
    const newHandlers = [...data.handlers];
    newHandlers.splice(index, 1);
    setData({ ...data, handlers: newHandlers });
  };

  const addHandler = (evt) => {
    evt.preventDefault();
    setData({ ...data, handlers: [...data.handlers, { ...DEFAULT_HANDLER }] });
  };

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setErrors(null);
    setIsSaving(true);

    apiClient.post(`/convert/workflows`, data).then(res => {
      navigate(`/convert/workflows/${res.data.id}`);
    }).catch(err => {
      setIsSaving(false);
      if (err.response?.status === 422) {
        const responseErrors = err.response.data.errors;
        // Convert error arrays to single-element arrays and flatten nested errors
        const formattedErrors: Record<string, [string]> = {};
        Object.entries(responseErrors).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            formattedErrors[key] = [value[0]];
          } else if (typeof value === 'object') {
            // Handle nested errors (e.g., handlers.0.event)
            Object.entries(value as Record<string, string[]>).forEach(([nestedKey, nestedValue]) => {
              formattedErrors[`${key}.${nestedKey}`] = [nestedValue[0]];
            });
          }
        });
        setErrors(formattedErrors);
      } else {
        setErrors({ general: [err.response?.data?.message || 'An error occurred'] });
      }
    })
  }

  const handleDescriptors = useMemo(() => {
    return data.handlers.map((handler) => {
      if (!handler.listen) {
        return null;
      }
      if (handler.listen === 'custom') {
        return `When custom event "${handler.event_name || ""}" is triggered`;
      }

      const qualifier = qualifierMap[handler.qualifier] || handler.qualifier;
      const event = eventMap[handler.event_name] || handler.event_name;
      const props = Object.keys(handler.props || {}).map((key) => {
        return `${key} "${handler.props[key]}"`;
      }).join(' or ');

      return `When ${qualifier} ${props} ${event}`;
    }).filter(f => f);
  }, [data.handlers]);

  if (!clients) return <div>Loading...</div>
  if (!clients.data.length) return (
    <Alert title="No clients found">
      You need at least one client to create a workflow
    </Alert>
  )

  return (
    <div className="p-10">
      <div className="mb-8">
        <H1>Create Workflow</H1>
        <P className="text-muted-foreground text-lg mt-3">Configure a new workflow to manage user interactions and conversions</P>
      </div>

      <Form onSubmit={handleSubmit} className="space-y-6">
        {errors?.general && (
          <Alert title="Error">
            {errors.general[0]}
          </Alert>
        )}

        <div className="bg-card rounded-lg border shadow-sm">
          <div className="p-6">
            <div className="grid grid-cols-2 gap-8">
              <div className="space-y-3">
                <label htmlFor="name" className="text-sm font-medium">Name</label>
                <Input
                  id="name"
                  placeholder="Enter workflow name"
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  aria-invalid={!!errors?.name}
                />
                {errors?.name && (
                  <p className="text-sm text-destructive">{errors.name[0]}</p>
                )}
              </div>

              <div className="space-y-3">
                <label htmlFor="lookup_key" className="text-sm font-medium">Lookup Key</label>
                <Input
                  id="lookup_key"
                  placeholder="Enter lookup key"
                  value={data.lookup_key}
                  onChange={(e) => setData({ ...data, lookup_key: e.target.value })}
                  aria-invalid={!!errors?.lookup_key}
                />
                {errors?.lookup_key && (
                  <p className="text-sm text-destructive">{errors.lookup_key[0]}</p>
                )}
              </div>
            </div>
          </div>
        
          <div className="border-b p-6">
            <div className="flex justify-between items-center">
              <div>
                <H3>Workflow Handlers</H3>
                <P className="text-muted-foreground text-sm mt-1">Define when this workflow should be triggered</P>
              </div>
              {data.handlers.length > 0 && (
                <Button 
                  onClick={addHandler}
                  variant="outline"
                  size="sm"
                >
                  <PlusIcon className="w-4 h-4 mr-2" />
                  Add Handler
                </Button>
              )}
            </div>
          </div>

          <div className="p-6">
            {data.handlers.length === 0 ? (
              <div className="flex flex-col items-center justify-center py-16">
                <div className="mb-4 rounded-full bg-muted p-3">
                  <PlusIcon className="w-6 h-6 text-muted-foreground" />
                </div>
                <h3 className="text-lg font-semibold mb-2">No handlers configured</h3>
                <P className="text-muted-foreground mb-6">Add your first handler to define when this workflow should trigger</P>
                <Button 
                  onClick={addHandler}
                  variant="default"
                >
                  <PlusIcon className="w-4 h-4 mr-2" />
                  Add your first handler
                </Button>
              </div>
            ) : (
              <div className="space-y-4">
                {data.handlers.map((handler, index) => {
                  const handlerErrors = errors ? 
                    Object.entries(errors)
                      .filter(([key]) => key.startsWith(`handlers.${index}.`))
                      .reduce((acc, [key, value]) => {
                        const fieldName = key.split('.')[2];
                        acc[fieldName] = value;
                        return acc;
                      }, {} as Record<string, [string]>) 
                    : undefined;

                  return (
                    <HandlerForm
                      key={index}
                      handler={handler}
                      onChange={(handler) => handleHandlerChange(index, handler)}
                      onDelete={() => handleHandlerDelete(index)}
                      errors={handlerErrors}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {/* Debug Output - only show in development */}
        {process.env.NODE_ENV === 'development' && (
          <div className="bg-muted/50 rounded-lg p-6">
            <pre className="text-xs text-muted-foreground whitespace-pre-wrap">
              {JSON.stringify({
                data,
                errors,
              }, null, 2)}
            </pre>
          </div>
        )}

        <div className="flex items-center justify-end space-x-4 pt-6">
          <Button 
            type="button"
            variant="outline"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button 
            type="submit"
            disabled={isSaving}
          >
            {isSaving ? 'Creating...' : 'Create Workflow'}
          </Button>
        </div>
      </Form>
    </div>
  )
}
