import { useState, useEffect } from "react";
import { usePlandalf } from "@plandalf/react-plandalf-js";
import { Button, Card, Heading, Text, Flex } from "@radix-ui/themes";
import apiClient from "../lib/apiClient";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Define the type for MRR data
interface MRRData {
  month: string;
  currency: string;
  amount_cents: number;
}

export default function TestPage() {
  const p = usePlandalf();
  const [mrrData, setMrrData] = useState<MRRData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Fetch MRR data
  const fetchMRRData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiClient.get<MRRData[]>("/reports/mrr");
      setMrrData(response.data);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch MRR data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMRRData();
  }, []);

  // Group data by currency
  const groupedByCurrency = mrrData.reduce<Record<string, MRRData[]>>((acc, item) => {
    if (!acc[item.currency]) {
      acc[item.currency] = [];
    }
    acc[item.currency].push(item);
    return acc;
  }, {});

  // Generate chart data for each currency
  const generateChartData = (currencyData: MRRData[]) => {
    // Sort by month
    const sortedData = [...currencyData].sort((a, b) => a.month.localeCompare(b.month));
    
    return {
      labels: sortedData.map(item => item.month),
      datasets: [
        {
          label: `MRR (${sortedData[0]?.currency.toUpperCase() || ""})`,
          data: sortedData.map(item => item.amount_cents / 100), // Convert cents to dollars/units
          borderColor: sortedData[0]?.currency === "usd" ? "rgb(53, 162, 235)" : "rgb(255, 99, 132)",
          backgroundColor: sortedData[0]?.currency === "usd" ? "rgba(53, 162, 235, 0.5)" : "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value: any) {
            return value.toLocaleString();
          }
        }
      }
    }
  };

  const handleTestEvent = () => {
    try {
      p.plandalf.trigger("TestEvent");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="p-12">
      <Heading size="6" className="mb-6">Monthly Recurring Revenue</Heading>
      
      {loading && <Text className="text-gray-700">Loading MRR data...</Text>}
      
      {error && (
        <Card className="p-4 mb-4 bg-red-50">
          <Text className="text-gray-700">{error}</Text>
          <Button onClick={fetchMRRData} className="mt-2">Retry</Button>
        </Card>
      )}

      {!loading && !error && Object.keys(groupedByCurrency).length === 0 && (
        <Text className="text-gray-700">No MRR data available</Text>
      )}

      <Flex direction="column" gap="4">
        {Object.entries(groupedByCurrency).map(([currency, data]) => (
          <Card key={currency} className="p-4">
            <Heading size="4" className="mb-4 text-gray-700">
              {currency.toUpperCase()} Revenue
            </Heading>
            <div className="h-64">
              <Line 
                options={chartOptions} 
                data={generateChartData(data)} 
              />
            </div>
          </Card>
        ))}
      </Flex>

      <div className="mt-8">
        <Button onClick={handleTestEvent}>Trigger TestEvent</Button>
      </div>
    </div>
  );
}