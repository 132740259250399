import { useState, useMemo } from 'react';
import useSWR from 'swr';
import { getFetcher } from '~/lib/apiClient';
import { Link, useSearchParams } from 'react-router-dom';
import { AlertTriangle } from 'lucide-react';
import { H1 } from "~/components/ui/typography";
import { Button } from "~/components/ui/button";
import { Badge } from "~/components/ui/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import OrganizationLayout from "~/layouts/OrganizationLayout";

interface Twin {
  id: string;
  object: 'twin';
  type: string;
  data: Record<string, any>;
  name: string;
  provider: string;
  environment: 'test' | 'live';
}

interface Charge {
  id: string;
  object: 'charge';
  product?: {
    object: 'product';
    id: string;
  };
  type: string;
  mode: string;
  name: string;
  amount: number;
  amount_formatted: string;
  currency: string;
  properties: Record<string, any>;
  twins?: Twin[];
  inclusions: Array<{ id: string; plan: string }>;
}

interface ChargesResponse {
  data: Charge[];
  meta: PaginationMeta;
}

interface PaginationMeta {
  current_page: number;
  from: number;
  to: number;
  total: number;
  last_page: number;
}

interface Bsp {
  id: string;
  type: string;
  environment: 'live' | 'test';
  name: string;
  current_state: string;
  provider_id: string;
}

function BillingProvidersList({ providers }: { providers: Bsp[] }) {
  if (!providers?.length) return null;

  return (
    <div className="mb-6">
      <h3 className="text-lg font-medium mb-2">Connected Billing Providers</h3>
      <div className="flex gap-2">
        {providers.map((bsp) => (
          <Badge
            key={bsp.id}
            variant={bsp.environment === 'live' ? 'default' : 'secondary'}
          >
            {bsp.name} ({bsp.environment})
          </Badge>
        ))}
      </div>
    </div>
  );
}

export default function ChargesListPage() {
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get("page")) || 1;

  const { data: collection, error } = useSWR<ChargesResponse>(
    `/billing/charges?page=${page}`,
    getFetcher
  );
  const { data: bspData } = useSWR<{ data: Bsp[] }>(
    '/billing/providers',
    getFetcher
  );

  const getBillingProviderStatus = (twins?: Twin[]) => {
    if (!twins?.length) return 'No providers';
    
    const hasTestProvider = twins.some(twin => twin.environment === 'test');
    const hasLiveProvider = twins.some(twin => twin.environment === 'live');
    
    if (hasTestProvider && hasLiveProvider) return 'Test & Live';
    if (hasTestProvider) return 'Test only';
    if (hasLiveProvider) return 'Live only';
    return 'Unknown';
  };

  return (
    <OrganizationLayout>
      <div className="space-y-6">
        <div>
          <H1>Charges</H1>
          <p className="text-muted-foreground mt-2">
            Manage your billing charges and pricing
          </p>
        </div>

        {bspData?.data && <BillingProvidersList providers={bspData.data} />}

        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Type</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Mode</TableHead>
                <TableHead>Properties</TableHead>
                <TableHead>Billing Provider Status</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {collection?.data.map((charge) => (
                <TableRow key={charge.id}>
                  <TableCell>
                    <Link 
                      to={`/billing/charges/${charge.id}`}
                      className="font-medium text-primary hover:underline"
                    >
                      {charge.name}
                    </Link>

                    {charge.inclusions?.length > 0 && (
                      <div className="mt-2">
                        <h4 className="text-sm font-medium">Included in plans:</h4>
                        <ul className="text-sm space-y-1">
                          {charge.inclusions.map(inclusion => (
                            <li key={inclusion.id}>
                              <Link 
                                to={`/catalog/pricing/plans/${inclusion.plan}`}
                                className="text-primary hover:underline"
                              >
                                {inclusion.plan}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>{charge.type}</TableCell>
                  <TableCell>{charge.amount_formatted}</TableCell>
                  <TableCell>{charge.mode}</TableCell>
                  <TableCell>
                    <pre className="text-sm bg-muted p-2 rounded-md overflow-x-auto">
                      {JSON.stringify(charge.properties, null, 2)}
                    </pre>
                  </TableCell>
                  <TableCell>
                    <Badge
                      variant={
                        getBillingProviderStatus(charge.twins) === 'Test & Live'
                          ? 'default'
                          : getBillingProviderStatus(charge.twins) === 'Test only'
                          ? 'secondary'
                          : getBillingProviderStatus(charge.twins) === 'Live only'
                          ? 'outline'
                          : 'secondary'
                      }
                      className="flex items-center gap-1"
                    >
                      {getBillingProviderStatus(charge.twins) !== 'Test & Live' && (
                        <AlertTriangle className="h-3 w-3" />
                      )}
                      {getBillingProviderStatus(charge.twins)}
                    </Badge>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        {collection && (
          <div className="flex items-center justify-between">
            <p className="text-sm text-muted-foreground">
              Showing {collection.meta.from} to {collection.meta.to} of {collection.meta.total} results
            </p>

            <div className="flex gap-2">
              <Button
                variant="outline"
                size="sm"
                asChild
                disabled={collection.meta.current_page === 1}
              >
                <Link to={`/billing/charges?page=${collection.meta.current_page - 1}`}>
                  Previous
                </Link>
              </Button>
              <Button
                variant="outline"
                size="sm"
                asChild
                disabled={collection.meta.current_page === collection.meta.last_page}
              >
                <Link to={`/billing/charges?page=${collection.meta.current_page + 1}`}>
                  Next
                </Link>
              </Button>
            </div>
          </div>
        )}
      </div>
    </OrganizationLayout>
  );
}