import {useParams} from "react-router";
import useSWR, {mutate} from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import {Link} from "react-router-dom";
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Flex, Button, Card, Badge, Skeleton, Avatar } from '@radix-ui/themes';
import {Heading, TabNav, Dialog, Text, Switch} from "@radix-ui/themes";

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import {get} from "lodash";
import WorkflowLayout from "~/pages/convert/workflows/WorkflowLayout";
import TextInput from "~/components/form/TextInput";
import * as Form from "@radix-ui/react-form";
import update from "immutability-helper";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { BarChartBigIcon, CloudLightning, CloudLightningIcon, CodeIcon, EditIcon, TextCursorIcon, ArrowUpRight } from "lucide-react";
import AnchorButton from "~/components/AnchorButton";
import { GlobalStatsComponent } from "../ConvertHomePage";
import { useLocalStorage } from '~/hooks/useLocalStorage';
import { isEqual } from 'lodash';
import { avatarLetters } from "~/pages/customers/CustomersListPage";
import {
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Table,
} from "~/components/ui/table";
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Select,
} from "~/components/ui/select";

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PAYWALL_BASE_URL = process.env.PLANDALF_PAYWALL_URL;

export function Alert({children}) {
  return (
    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            {children}
          </p>
        </div>
      </div>
    </div>
  )
}

const qualifierMap = {
  findButtonByText: 'button containing'
}
const eventMap = {
  click: 'is clicked',
}
const listenMap = {
  attachment: 'When ',
  custom: 'When custom event'
}

function StatItem({ label, value }) {
  return (
    <div className="flex flex-col items-center space-y-1 p-4 justify-between">
      <div className="text-4xl font-medium">{value}</div>
      <div className="text-xs font-semibold tabular-nums">{label}</div>
    </div>
  );
}

function formatTime(seconds) {
  if (!seconds) return '00:00';
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

// Update the Client interface
interface Client {
  id: number;
  name: string;
  environment: 'live' | 'test';
}

interface Rollout {
  client: Client;
}

interface Workflow {
  id: number;
  name: string;
  rollouts?: Rollout[];
  // Add other workflow properties as needed
}

function DateRangeFilter({ workflow }: { workflow: Workflow }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [range, setRange] = useState('last_30_days');
  const [client, setClient] = useState('');
  
  const { data: clients } = useSWR('/clients', getFetcher);

  const getLiveClient = () => {
    return clients?.data.find(c => c.environment === 'live')?.id.toString() || '';
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlClient = params.get('client');
    const liveClient = clients?.data.find(c => c.environment === 'live')?.id.toString();
    
    setRange(params.get('range') || 'last_30_days');
    setClient(urlClient || liveClient || '');
    
    if (!urlClient && liveClient) {
      updateFilters(params.get('range') || 'last_30_days', liveClient);
    }
  }, [location, clients]);

  const updateFilters = (newRange: string, newClient: string) => {
    const params = new URLSearchParams();
    params.set('range', newRange);
    if (newClient) params.set('client', newClient);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  return (
    <div className="flex gap-3 items-center">
      <Select value={range} onValueChange={(value) => updateFilters(value, client)}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select timeframe" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="last_30_days">Last 30 Days</SelectItem>
          <SelectItem value="last_7_days">Last 7 Days</SelectItem>
          <SelectItem value="today">Today</SelectItem>
          <SelectItem value="yesterday">Yesterday</SelectItem>
          <SelectItem value="this_month">This Month</SelectItem>
          <SelectItem value="last_month">Last Month</SelectItem>
          <SelectItem value="this_year">This Year</SelectItem>
          <SelectItem value="last_year">Last Year</SelectItem>
          <SelectItem value="all_time">All Time</SelectItem>
        </SelectContent>
      </Select>

      <Select value={client} onValueChange={(value) => updateFilters(range, value)}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="All Clients" />
        </SelectTrigger>
        <SelectContent>
          {clients?.data?.map((client) => (
            <SelectItem key={client.id} value={client.id.toString()}>
              {client.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Button 
        variant="outline" 
        onClick={() => updateFilters('last_30_days', getLiveClient())}
      >
        Reset
      </Button>
    </div>
  );
}

function LineGraph({ chartData }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index' as const,
        intersect: false,
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 6,
          autoSkip: true,
        },
        grid: {
          display: true,
          color: 'rgba(0, 0, 0, 0.1)',
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };
  
  const data = useMemo(() => {
    if (!chartData || !Array.isArray(chartData) || chartData.length === 0) {
      return {
        labels: [],
        datasets: [
          {
            label: 'Views',
            data: [],
            borderColor: '#49B7D1',
            backgroundColor: '#49B7D1',
            fill: 'start',
            order: 3,
          },
          {
            label: 'Starts',
            data: [],
            borderColor: '#A1DCEB',
            backgroundColor: '#A1DCEB',
            fill: 'start',
            order: 2,
          },
          {
            label: 'Conversions',
            data: [],
            borderColor: '#CCF0FA',
            backgroundColor: '#CCF0FA',
            fill: 'start',
            order: 1,
          },
        ],
      };
    }
    
    // Sort data by date
    const sortedData = [...chartData].sort((a, b) => new Date(a.date) - new Date(b.date));
    
    return {
      labels: sortedData.map(d => d.date),
      datasets: [
        {
          label: 'Views',
          data: sortedData.map(d => d.views),
          borderColor: '#49B7D1',
          backgroundColor: (context) => {
            const value = context.raw;
            return value > 0 ? '#49B7D1' : 'transparent';
          },
          fill: 'start',
          order: 3,
        },
        {
          label: 'Starts',
          data: sortedData.map(d => d.starts),
          borderColor: '#A1DCEB',
          backgroundColor: (context) => {
            const value = context.raw;
            return value > 0 ? '#A1DCEB' : 'transparent';
          },
          fill: 'start',
          order: 2,
        },
        {
          label: 'Conversions',
          data: sortedData.map(d => d.converts),
          borderColor: '#7eb5007a',
          backgroundColor: (context) => {
            const value = context.raw;
            return value > 0 ? '#7EB500' : 'transparent';
          },
          fill: 'start',
          order: 1,
        },
      ],
    };
  }, [chartData]);

  return (
    <div style={{ height: '220px' }}>
      <Line options={options} data={data} />
    </div>
  );
}

function OverviewGridComponent({ workflow }) {
  const location = useLocation();
  const [activeFilters, setActiveFilters] = useState(() => {
    // Initialize from URL params
    const params = new URLSearchParams(location.search);
    const range = params.get('range');
    const client = params.get('client');
    
    // If custom range, get the date values
    const from = params.get('from') || '';
    const to = params.get('to') || '';

    // Check localStorage if no URL params
    const storedFilters = localStorage.getItem('workflow-filters');
    const parsedStoredFilters = storedFilters ? JSON.parse(storedFilters) : null;

    // Use URL params if they exist, otherwise use stored filters, finally fall back to defaults
    return {
      range: range || (parsedStoredFilters?.range) || 'last_30_days',
      client: client || (parsedStoredFilters?.client) || '',
      customRange: range === 'custom' 
        ? { from, to }
        : (parsedStoredFilters?.customRange || { from: '', to: '' })
    };
  });

  // Build query string for stats
  const queryString = useMemo(() => {
    const params = new URLSearchParams({
      range: activeFilters.range,
      ...(activeFilters.client && { client: activeFilters.client }),
      ...(activeFilters.range === 'custom' && {
        from: activeFilters.customRange.from,
        to: activeFilters.customRange.to
      })
    });
    
    // Add timezone
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (tz) params.set('tz', tz);
    
    return params.toString();
  }, [activeFilters]);

  const { data: results, error: resultsError } = useSWR(
    `/intel/flows/${workflow.id}/results${queryString ? `?${queryString}` : ''}`,
    getFetcher
  );

  // Listen for URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const range = params.get('range');
    const client = params.get('client');
    
    if (range || client) {
      setActiveFilters(current => ({
        ...current,
        range: range || current.range,
        client: client || current.client,
        customRange: range === 'custom' 
          ? { 
              from: params.get('from') || current.customRange.from, 
              to: params.get('to') || current.customRange.to 
            }
          : current.customRange
      }));
    }
  }, [location.search]);

  const memoizedChartData = useMemo(() => results?.chart_data, 
    [results?.chart_data], 
    isEqual
  );

  return (
    <>
      <div className="flex gap-4 pt-4">
        <DateRangeFilter 
          workflow={workflow}
          onFiltersChange={setActiveFilters}
        />
      </div>
      
      <GlobalStatsComponent 
        stats={{ 
          isLoading: !results && !resultsError,
          data: { stats: results?.global_stats }
        }} 
      />

      {/* Add the LineGraph component here */}
      {results?.chart_data && (
        <div className="bg-gray-50 border rounded-lg min-h-[280px] overflow-y-hidden">
          
          <div className="p-4 bg-white">
            <LineGraph chartData={memoizedChartData} />
          </div>
        </div>
      )}
  
    </>
  );
}

interface PaywallStats {
  starts_count: number;
  views_count: number;
  views_unique_count: number;
  conversions_count: number;
  bounce_rate: number;
  conversion_rate: number;
}

interface Paywall {
  id: string;
  name: string;
  stats: PaywallStats;
}

function formatRate(rate: number | null | undefined): string {
  return typeof rate === 'number' ? `${rate.toFixed(1)}%` : '0.0%';
}

function formatCurrency(amount: number | null | undefined, currency: string): string {
  return typeof amount === 'number' ? `$${(amount/100).toFixed(2)} ${currency}` : '0.00';
}

function PaywallStatsTable({ workflow, queryString }) {
  const { data: paywalls, error } = useSWR<{ data: Paywall[] }>(
    `/intel/flows/${workflow.id}/paywalls${queryString ? `${queryString}` : ''}`,
    getFetcher
  );

  if (error) return <div>Failed to load paywall stats</div>;
  if (!paywalls) return <div>Loading...</div>;

  // Calculate overall stats only if there's more than 1 paywall
  const showOverall = paywalls.data.length > 1;
  const overall = showOverall ? paywalls.data.reduce((acc, curr) => {
    return {
      unique_views: {
        value: (acc.unique_views?.value || 0) + (curr.stats?.unique_views?.value || 0)
      },
      views_count: {
        value: (acc.views_count?.value || 0) + (curr.stats?.views_count?.value || 0)
      },
      starts_count: {
        value: (acc.starts_count?.value || 0) + (curr.stats?.starts_count?.value || 0)
      },
      conversions_count: {
        value: (acc.conversions_count?.value || 0) + (curr.stats?.conversions_count?.value || 0)
      },
      revenue_sum: {
        value: ((acc.revenue_sum?.value || 0) + (Number(curr.stats?.revenue_sum?.value?.replace(/[^0-9.-]+/g, '')) || 0))
      }
    };
  }, {
    unique_views: { value: 0 },
    views_count: { value: 0 },
    starts_count: { value: 0 },
    conversions_count: { value: 0 },
    revenue_sum: { value: 0 }
  }) : null;

  // Calculate rates for overall stats
  if (showOverall && overall) {
    // Bounce rate: (views without starts) / total views * 100
    const bounceRate = ((overall.views_count.value - overall.starts_count.value) / overall.views_count.value) * 100;
    overall.bounce_rate = { value: `${bounceRate.toFixed(1)}%` };
    
    // Conversion rate: total conversions / total unique views * 100
    overall.conversion_rate = { 
      value: `${((overall.conversions_count.value / overall.unique_views.value) * 100).toFixed(1)}%` 
    };
    
    overall.revenue_sum.value = `$${overall.revenue_sum.value.toFixed(2)}`;
  }

  return (
    <div className="space-y-4">
      <Heading size="4">Paywall Stats</Heading>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="border-r">Variant</TableHead>
            <TableHead>Unique Users</TableHead>
            <TableHead>Total Views</TableHead>
            <TableHead>Bounce Rate</TableHead>
            <TableHead className="border-r">Checkout Complete</TableHead>
            <TableHead>Conversion Rate</TableHead>
            <TableHead>Revenue</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {paywalls.data.map((paywall) => (
            <TableRow key={paywall.id}>
              <TableCell className="border-r">
                <Link className="text-primary hover:underline" to={`/convert/paywalls/${paywall.id}/edit`}>
                  {paywall.name}
                </Link>
              </TableCell>
              <TableCell>{paywall.stats?.unique_views?.value || 0}</TableCell>
              <TableCell>{paywall.stats?.views_count?.value || 0}</TableCell>
              <TableCell>{paywall.stats?.bounce_rate?.value || '0%'}</TableCell>
              <TableCell className="border-r">{paywall.stats?.conversions_count?.value || 0}</TableCell>
              <TableCell>{paywall.stats?.conversion_rate?.value || '0%'}</TableCell>
              <TableCell>{paywall.stats?.revenue_sum?.value || '$0.00'}</TableCell>
            </TableRow>
          ))}
          {showOverall && overall && (
            <TableRow className="font-medium bg-muted/50">
              <TableCell className="border-r">Overall</TableCell>
              <TableCell>{overall.unique_views.value}</TableCell>
              <TableCell>{overall.views_count.value}</TableCell>
              <TableCell>{overall.bounce_rate.value}</TableCell>
              <TableCell className="border-r">{overall.conversions_count.value}</TableCell>
              <TableCell>{overall.conversion_rate.value}</TableCell>
              <TableCell>{overall.revenue_sum.value}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

function ScenarioStatsComponent({ workflow, queryString }) {
  const { data: scenarios, error } = useSWR<{ data: Scenario[] }>(
    `/intel/flows/${workflow.id}/scenarios${queryString ? `${queryString}` : ''}`,
    getFetcher
  );

  if (error) return <div>Failed to load scenario stats</div>;
  if (!scenarios) return <div>Loading...</div>;

  // Calculate overall stats if there's more than 1 scenario
  const showOverall = scenarios.data.length > 1;
  const overall = showOverall ? scenarios.data.reduce((acc, curr) => {
    return {
      unique_views: {
        value: (acc.unique_views?.value || 0) + (curr.stats?.unique_views?.value || 0)
      },
      views_count: {
        value: (acc.views_count?.value || 0) + (curr.stats?.views_count?.value || 0)
      },
      starts_count: {
        value: (acc.starts_count?.value || 0) + (curr.stats?.starts_count?.value || 0)
      },
      conversions_count: {
        value: (acc.conversions_count?.value || 0) + (curr.stats?.conversions_count?.value || 0)
      },
      revenue_sum: {
        value: ((acc.revenue_sum?.value || 0) + (Number(curr.stats?.revenue_sum?.value?.replace(/[^0-9.-]+/g, '')) || 0))
      }
    };
  }, {
    unique_views: { value: 0 },
    views_count: { value: 0 },
    starts_count: { value: 0 },
    conversions_count: { value: 0 },
    revenue_sum: { value: 0 }
  }) : null;

  // Calculate rates for overall stats
  if (showOverall && overall) {
    const bounceRate = ((overall.views_count.value - overall.starts_count.value) / overall.views_count.value) * 100;
    overall.bounce_rate = { value: `${bounceRate.toFixed(1)}%` };
    overall.conversion_rate = { 
      value: `${((overall.conversions_count.value / overall.unique_views.value) * 100).toFixed(1)}%` 
    };
    overall.revenue_sum.value = `$${overall.revenue_sum.value.toFixed(2)}`;
  }

  return (
    <div className="space-y-4">
      <Heading size="4">Stats</Heading>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="border-r">Scenario</TableHead>
            <TableHead>Unique Users</TableHead>
            <TableHead>Total Views</TableHead>
            <TableHead>Starts</TableHead>
            <TableHead>Bounce Rate</TableHead>
            <TableHead className="border-r">Checkout Complete</TableHead>
            <TableHead>Conversion Rate</TableHead>
            <TableHead>Revenue</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {scenarios.data.map((scenario) => (
            <TableRow key={scenario.id}>
              <TableCell className="border-r">
                <div className="flex flex-col">
                  <span>{scenario.display_name}</span>
                  <span className="text-sm text-muted-foreground">{scenario.lookup_key}</span>
                  {scenario.offer ? (
                    <Link 
                      to={`/store/offers/${scenario.offer.id}`} 
                      className="text-xs text-primary hover:underline mt-1 flex items-center gap-1"
                    >
                      <span>View offer</span>
                      <ArrowUpRight className="h-3 w-3" />
                    </Link>
                  ) : (
                    <span className="text-xs text-red-500 mt-1 flex items-center gap-1">
                      <span>No offer linked</span>
                      <ExclamationTriangleIcon className="h-3 w-3" />
                    </span>
                  )}
                </div>
              </TableCell>
              <TableCell>{scenario.stats?.unique_views?.value || 0}</TableCell>
              <TableCell>{scenario.stats?.views_count?.value || 0}</TableCell>
              <TableCell>{scenario.stats?.starts_count?.value || 0}</TableCell>
              <TableCell>{scenario.stats?.bounce_rate?.value || '0%'}</TableCell>
              <TableCell className="border-r">{scenario.stats?.conversions_count?.value || 0}</TableCell>
              <TableCell>{scenario.stats?.conversion_rate?.value || '0%'}</TableCell>
              <TableCell>{scenario.stats?.revenue_sum?.value || '$0.00'}</TableCell>
            </TableRow>
          ))}
          {showOverall && overall && (
            <TableRow className="font-medium bg-muted/50">
              <TableCell className="border-r">Overall</TableCell>
              <TableCell>{overall.unique_views.value}</TableCell>
              <TableCell>{overall.views_count.value}</TableCell>
              <TableCell>{overall.starts_count.value}</TableCell>
              <TableCell>{isNaN(overall.bounce_rate.value) ? '-' : overall.bounce_rate.value}</TableCell>
              <TableCell className="border-r">{overall.conversions_count.value}</TableCell>
              <TableCell>{isNaN(overall.conversion_rate.value) ? '-' : overall.conversion_rate.value}</TableCell>
              <TableCell>{overall.revenue_sum.value === 0 ? '-' : overall.revenue_sum.value}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

export default function WorkflowDetailPage() {
  const params = useParams();
  const {data: workflow, error, mutate: mutateWorkflow} = useSWR(
    `/intel/flows/${params.id}`, 
    getFetcher
  );

  if (error) return <div>Failed to load</div>;
  if (!workflow) return <div>Loading...</div>;

  return (
    <WorkflowLayout workflow={workflow}>
      <div className="space-y-4">
        <OverviewGridComponent workflow={workflow} />
        {/* <PaywallStatsTable workflow={workflow} queryString={location.search} /> */}
        {/* <ConversionsListComponent workflow={workflow} /> */}

        <ScenarioStatsComponent workflow={workflow} queryString={location.search} />
      </div>
    </WorkflowLayout>
  );
}

