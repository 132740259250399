import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { H1 } from "~/components/ui/typography";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import OrganizationLayout from "~/layouts/OrganizationLayout";

interface FeatureSetCollection {
  data: FeatureSet[];
}

interface FeatureSet {
  id: string;
  name: string;
  description: string;
  created_at: number;
  updated_at: number;
}

export default function FeatureSetListPage() {
  const { data: collection, error } = useSWR<FeatureSetCollection>('/catalog/feature_sets', getFetcher);

  return (
    <OrganizationLayout>
      <div className="space-y-6">
        <div>
          <H1>Feature Sets</H1>
          <p className="text-muted-foreground mt-2">
            Manage your feature sets and configurations
          </p>
        </div>

        {error && (
          <div className="rounded-md bg-destructive/10 p-4 text-destructive">
            Failed to load feature sets
          </div>
        )}

        {!collection && (
          <div className="flex items-center justify-center h-32 text-muted-foreground">
            Loading...
          </div>
        )}

        {collection?.data.length === 0 && (
          <div className="flex items-center justify-center h-32 text-muted-foreground">
            No feature sets found
          </div>
        )}

        {collection && collection.data.length > 0 && (
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Description</TableHead>
                  <TableHead>Created</TableHead>
                  <TableHead>Updated</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>

              <TableBody>
                {collection.data.map(featureSet => (
                  <TableRow key={featureSet.id}>
                    <TableCell>
                      <div>
                        <a
                          href={`/catalog/feature_sets/${featureSet.id}/edit`}
                          className="font-medium text-primary hover:underline"
                        >
                          {featureSet.name}
                        </a>
                        <div className="text-sm text-muted-foreground">
                          {featureSet.id}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{featureSet.description || '-'}</TableCell>
                    <TableCell>
                      {new Date(featureSet.created_at * 1000).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {new Date(featureSet.updated_at * 1000).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <a
                        href={`/catalog/feature_sets/${featureSet.id}/edit`}
                        className="text-primary hover:underline"
                      >
                        Edit
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </OrganizationLayout>
  );
}
