import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import useSWR from "swr";
import apiClient, { getFetcher } from "~/lib/apiClient";
import { useAuth } from "~/hooks/useAuth";
import { useLocalStorage } from '~/hooks/useLocalStorage';
import { usePlandalf } from "@plandalf/react-plandalf-js";
import { toast } from 'react-toastify';
import { H1, H2 } from "~/components/ui/typography";
import { Button } from "~/components/ui/button";
import { Badge } from "~/components/ui/badge";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { 
  Check, 
  Loader2, 
  ClipboardIcon, 
  LinkIcon, 
  ExternalLink, 
  EyeIcon, 
  EyeOffIcon,
  RadioTower
} from "lucide-react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import cx from "classnames";
import { cn } from "~/lib/utils";
import { get } from "lodash";
import { Skeleton } from "~/components/ui/skeleton";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Client {
  id: string;
  label: string;
}

interface ClientsResponse {
  data: Client[];
}

interface StatValue {
  value: number;
  diff_percentage?: number;
  negate?: boolean;
}

interface Stats {
  unique_views: StatValue;
  bounce_rate: StatValue;
  conversions_count: StatValue;
  conversion_rate: StatValue;
  revenue_sum: StatValue;
}

interface StatsResponse {
  stats: Stats;
}

interface Filters {
  range: string;
  client: string;
  paywall: string;
  customRange: { from: string; to: string } | null;
}

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
  }[];
}

interface Conversion {
  id: string;
  created_at: string;
  customer: {
    email?: string;
    id: string;
  };
  amount: number;
}

interface ActivityEvent {
  id: string;
  date: string;
  created_at: string;
  customer: {
    email?: string;
    id: string;
  };
  type: string;
  views: number;
  starts: number;
  converts: number;
  bounces: number;
  flow?: {
    id: string;
  };
  variant_name?: string;
  paywall?: {
    workflow: string;
  };
  latest_event?: string;
}

interface ActivityResponse {
  data: ActivityEvent[];
}

function DateRangeFilter({ onFiltersChange }: { onFiltersChange: (filters: Filters) => void }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [range, setRange] = useState('last_30_days');
  const [client, setClient] = useState('');
  const [paywall, setPaywall] = useState('');
  const [storedFilters, setStoredFilters] = useLocalStorage('dashboard-filters', null);

  const { data: clients } = useSWR<ClientsResponse>('/clients', getFetcher);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlRange = params.get('range');
    const urlClient = params.get('client');
    const urlPaywall = params.get('paywall');

    if (!urlRange && !urlClient && !urlPaywall && storedFilters) {
      const filters = {
        range: storedFilters.range || 'last_30_days',
        client: storedFilters.client || '',
        paywall: storedFilters.paywall || '',
        customRange: storedFilters.customRange || null
      };
      updateFilters(filters.range, filters.client, filters.paywall, filters.customRange, false);
    } else {
      setRange(urlRange || 'last_30_days');
      setClient(urlClient || '');
      setPaywall(urlPaywall || '');
    }
  }, [location, storedFilters]);

  const updateFilters = (newRange: string, newClient: string, newPaywall: string, newCustomRange: { from: string; to: string } | null, updateStorage = true) => {
    const params = new URLSearchParams();
    params.set('range', newRange);
    if (newClient) params.set('client', newClient);
    if (newPaywall) params.set('paywall', newPaywall);
    if (newCustomRange) {
      params.set('from', newCustomRange.from);
      params.set('to', newCustomRange.to);
    }

    if (updateStorage) {
      setStoredFilters({
        range: newRange,
        client: newClient,
        paywall: newPaywall,
        customRange: newCustomRange
      });
    }

    navigate(`${location.pathname}?${params.toString()}`);
    onFiltersChange({ range: newRange, client: newClient, paywall: newPaywall, customRange: newCustomRange });
  };

  const handleReset = () => {
    setStoredFilters(null);
    updateFilters('last_30_days', '', '', null);
  };

  return (
    <div className="flex items-center gap-3">
      <Select value={range} onValueChange={(value) => updateFilters(value, client, paywall, null)}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select time range" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="today">Today</SelectItem>
          <SelectItem value="yesterday">Yesterday</SelectItem>
          <SelectItem value="last_7_days">Last 7 Days</SelectItem>
          <SelectItem value="last_30_days">Last 30 Days</SelectItem>
          <SelectItem value="last_365_days">Last 365 Days</SelectItem>
          <SelectItem value="this_month">This Month</SelectItem>
          <SelectItem value="last_month">Last Month</SelectItem>
          <SelectItem value="this_year">This Year</SelectItem>
          <SelectItem value="last_year">Last Year</SelectItem>
          <SelectItem value="all_time">All Time</SelectItem>
        </SelectContent>
      </Select>

      <Select 
        value={client}
        onValueChange={(value) => updateFilters(range, value, paywall, null)}
      >
        <SelectTrigger className="w-[200px]">
          <SelectValue placeholder="All Clients" />
        </SelectTrigger>
        <SelectContent>
          {clients?.data?.map((client) => (
            <SelectItem key={client.id} value={client.id.toString()}>
              {client.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Button variant="outline" onClick={handleReset}>
        Reset
      </Button>
    </div>
  );
}
export function GlobalStatsComponent({ stats }) {

  const data = get(stats, 'data.stats', { });

  return (
    <div className="grid grid-cols-5 gap-4 bg-gray-950 text-white rounded-lg divide-x min-h-28 items-center divide-gray-900">
      <StatItem isLoading={stats.isLoading} label="Unique Views" stat={data?.unique_views} />
      <StatItem isLoading={stats.isLoading} label="Bounce rate" stat={data?.bounce_rate} />
      <StatItem isLoading={stats.isLoading} label="Conversions" stat={data?.conversions_count} />
      <StatItem isLoading={stats.isLoading} label="Conversion Rate" stat={data?.conversion_rate} />
      <StatItem isLoading={stats.isLoading} label="Total Revenue" stat={data?.revenue_sum} />
    </div>
  );
}

interface Stat {
  value: number;
  diff_percentage: number;
  diff_relative: string;
  negate?: boolean;
  formatter?: string;
}
interface StatItemProps {
  label: string;
  stat: any;
  isLoading: boolean;
}

function StatItem({ label, stat, isLoading }: StatItemProps) {
  if (isLoading) {
    return <div className="flex justify-center"><Skeleton /></div>;
  }
  if (!stat) {
    return null;
  }

  const formatValue = (value: number, formatter?: string) => {
    if (formatter === 'currency') {
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // notation: 'compact',
        // minimumFractionDigits: 0,
        // maximumFractionDigits: 1
      }).format(value / 100);
      
      // Convert K/M/B to lowercase
      return formatted; //.replace(/K|M|B/g, match => match.toLowerCase());
    }

    // For non-currency values, use compact notation
    const formatted = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1
    }).format(value);
    
    // Convert K/M/B to lowercase
    return formatted.replace(/K|M|B/g, match => match.toLowerCase());
  };

  return (
   <div className="flex h-full items-center justify-center">
     <div className="flex flex-col space-y-0.5 p-4">
      <div className="text-4xl font-medium leading-none">
        {stat.value}
      </div>
      <div className="text-sm tabular-nums">{label}</div>
      <div className="text-xs">
        {stat.diff_percentage !== null && (
          <>
            <span className={cx({
              "text-[#7EB500]": (!stat.negate && stat.diff_percentage > 0) || (stat.negate && stat.diff_percentage < 0),
              "text-red-500": (!stat.negate && stat.diff_percentage < 0) || (stat.negate && stat.diff_percentage > 0),
            })}>
              {stat.diff_percentage > 0 ? '+' : ''}{stat.diff_percentage}%
            </span>
            &nbsp;
            <span className="text-gray-500">
              {stat.diff_relative === 0 ? '-' : 
               `${stat.diff_relative > 0 ? '+' : ''}${formatValue(Math.round(stat.diff_relative), stat.formatter)} this period`}
            </span>
          </>
        )}
      </div>
    </div>
   </div>
  );
}

function OverviewGridComponent() {
  const location = useLocation();
  const [activeFilters, setActiveFilters] = useState(() => {
    // Initialize from URL params
    const params = new URLSearchParams(location.search);
    const range = params.get('range');
    const client = params.get('client');
    const paywall = params.get('paywall');
    
    // If custom range, get the date values
    const from = params.get('from') || '';
    const to = params.get('to') || '';

    // Check localStorage if no URL params
    const storedFilters = localStorage.getItem('dashboard-filters');
    const parsedStoredFilters = storedFilters ? JSON.parse(storedFilters) : null;

    // Use URL params if they exist, otherwise use stored filters, finally fall back to defaults
    return {
      range: range || (parsedStoredFilters?.range) || 'last_30_days',
      client: client || (parsedStoredFilters?.client) || '',
      paywall: paywall || (parsedStoredFilters?.paywall) || '',
      customRange: range === 'custom' 
        ? { from, to }
        : (parsedStoredFilters?.customRange || { from: '', to: '' })
    };
  });

  // Build query string for stats
  const queryString = new URLSearchParams({
    range: activeFilters.range,
    ...(activeFilters.client && { client: activeFilters.client }),
    ...(activeFilters.paywall && { paywall: activeFilters.paywall }),
    ...(activeFilters.range === 'custom' && {
      from: activeFilters.customRange.from,
      to: activeFilters.customRange.to
    })
  }).toString();

  const stats = useSWR(`/dashboard/stats?${queryString}`, getFetcher);

  // Listen for URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const range = params.get('range');
    const client = params.get('client');
    const paywall = params.get('paywall');
    
    if (range || client || paywall) {
      setActiveFilters(current => ({
        ...current,
        range: range || current.range,
        client: client || current.client,
        paywall: paywall || current.paywall,
        customRange: range === 'custom' 
          ? { 
              from: params.get('from') || current.customRange.from, 
              to: params.get('to') || current.customRange.to 
            }
          : current.customRange
      }));
    }
  }, [location.search]);

  return (
    <>
      <div className="flex gap-4">
        <DateRangeFilter 
          onFiltersChange={setActiveFilters}
        />
      </div>
      <GlobalStatsComponent stats={stats} />
    </>
  );
}

function ActivityComponent() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const clientParam = params.get('client') ? `?client=${params.get('client')}` : '';

  const { data: activityData } = useSWR<ActivityResponse>(`/dashboard/activity${clientParam}`, getFetcher);
  const activity = activityData?.data || [];

  const chartData = {
    labels: activity.map(item => new Date(item.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Views',
        data: activity.map(item => item.views),
        backgroundColor: '#49B7D1',
        stack: 'stack1',
        barPercentage: 1,
        categoryPercentage: 0.8,
      },
      {
        label: 'Starts',
        data: activity.map(item => item.starts),
        backgroundColor: '#A1DCEB',
        stack: 'stack1',
        barPercentage: 1,
        categoryPercentage: 0.8,
      },
      {
        label: 'Conversions',
        data: activity.map(item => item.converts),
        backgroundColor: '#7eb5007a',
        stack: 'stack1',
        barPercentage: 1,
        categoryPercentage: 0.8,
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        display: false,
        offset: true,
        grid: {
          offset: false
        }
      },
      y: {
        stacked: true,
        display: false,
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        mode: 'index' as const,
        intersect: false,
        callbacks: {
          title: (context: any) => {
            return new Date(activity[context[0].dataIndex].date).toLocaleDateString();
          },
          label: (context: any) => {
            const label = context.dataset.label;
            const value = context.raw;
            return `${label}: ${value}`;
          }
        }
      }
    },
  };

  if (!activity?.length) {
    return (
      <Card>
        <CardContent className="pt-6">
          <p className="text-muted-foreground">No activity data available</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <H2>Activity</H2>
      </CardHeader>
      <CardContent>
        <div className="h-[230px]">
          <Bar options={options} data={chartData} />
        </div>
      </CardContent>
    </Card>
  );
}

interface RevenueItem {
  date: string;
  amount: number;
}

interface RevenueTotals {
  current: number;
  difference: number;
  difference_percentage: number;
}

interface RevenueResponse {
  current: RevenueItem[];
  previous: RevenueItem[];
  totals: RevenueTotals;
}

function RevenueTrendComponent() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const clientParam = params.get('client') ? `?client=${params.get('client')}` : '';

  const { data } = useSWR<RevenueResponse>(`/dashboard/revenue${clientParam}`, getFetcher);

  if (!data) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="flex items-center justify-center">
            <Loader2 className="h-4 w-4 animate-spin" />
          </div>
        </CardContent>
      </Card>
    );
  }

  const maxAmount = Math.max(
    ...data.current.map(item => item.amount / 100),
    ...data.previous.map(item => item.amount / 100)
  );

  const getNiceScale = (max: number) => {
    if (max < 100) max = 100;
    const magnitude = Math.pow(10, Math.floor(Math.log10(max)));
    let niceMax = Math.ceil(max / magnitude) * magnitude;
    if (niceMax > max * 2) {
      niceMax = niceMax / 2;
    }
    return {
      max: niceMax,
      step: niceMax / 2
    };
  };

  const scale = getNiceScale(maxAmount);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: value >= 1000000 ? 'compact' : 'standard',
      maximumFractionDigits: value >= 1000000 ? 1 : 0
    }).format(value);
  };

  const chartData = {
    labels: data.current.map(item => new Date(item.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Current Period',
        data: data.current.map(item => item.amount / 100),
        borderColor: '#008DA7',
        backgroundColor: '#008DA7',
        fill: false,
        tension: 0,
        pointRadius: 0,
        borderWidth: 2,
      },
      {
        label: 'Previous Period',
        data: data.previous.map(item => item.amount / 100),
        borderColor: '#E2E7FF',
        backgroundColor: '#E2E7FF',
        fill: false,
        tension: 0,
        pointRadius: 0,
        borderWidth: 2,
      }
    ]
  };

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        display: true,
        min: 0,
        max: 100,
        grid: {
          color: '#f1f5f9',
          display: true,
          lineWidth: 1,
        },
        ticks: {
          display: true,
          count: 6,
          callback: (value: number) => `${value}%`,
          padding: 10,
          align: 'center',
          color: '#64748b',
          font: {
            size: 11,
            weight: 500,
          },
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: '#ffffff',
        titleColor: '#0f172a',
        titleFont: {
          size: 13,
          weight: 600,
        },
        bodyColor: '#64748b',
        bodyFont: {
          size: 12,
        },
        borderColor: '#e2e8f0',
        borderWidth: 1,
        padding: 12,
        displayColors: false,
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    elements: {
      line: {
        tension: 0.3,
      },
    },
  };

  return (
    <Card>
      <CardHeader>
        <H2>New Revenue</H2>
        <div className="flex items-baseline gap-2">
          <div className="text-lg font-medium">
            {formatCurrency(data.totals.current / 100)}
          </div>
          <div className={cx("text-sm font-semibold flex items-center", {
            "text-emerald-600": data.totals.difference > 0,
            "text-destructive": data.totals.difference < 0,
          })}>
            {data.totals.difference > 0 ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 14 16" fill="none">
                <path d="M3.59424 9.59999H9.88409L6.73917 4.79999L3.59424 9.59999Z" fill="currentColor"/>
              </svg>
            ) : (
              <div className="rotate-180">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 14 16" fill="none">
                  <path d="M3.59424 9.59999H9.88409L6.73917 4.79999L3.59424 9.59999Z" fill="currentColor"/>
                </svg>
              </div>
            )}
            {formatCurrency(data.totals.difference / 100)}
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="h-[200px]">
          <Line options={chartOptions} data={chartData} />
        </div>
      </CardContent>
    </Card>
  );
}

interface Customer {
  email?: string;
  id: string;
  avatar_url?: string;
}

interface ConversionsResponse {
  data: Conversion[];
}

function ConversionsListComponent() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const clientParam = params.get('client') ? `?client=${params.get('client')}` : '';

  const { data: conversions } = useSWR<ConversionsResponse>(`/dashboard/conversions${clientParam}`, getFetcher);

  if (!conversions) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="flex items-center justify-center">
            <Loader2 className="h-4 w-4 animate-spin" />
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <H2>Conversions</H2>
      </CardHeader>
      <CardContent>
        <div className="divide-y divide-border max-h-[220px] overflow-y-auto">
          {conversions.data.map((conversion) => (
            <div 
              key={conversion.id}
              className="flex items-center justify-between py-2"
            >
              <div className="flex items-center gap-3">
                <div className="h-8 w-8 rounded-full bg-muted flex items-center justify-center text-sm font-medium">
                  {conversion.customer?.email?.[0]?.toUpperCase() || '?'}
                </div>
                <div>
                  <div className="text-sm font-medium">
                    {conversion.customer?.email || conversion.customer?.id}
                  </div>
                  <div className="text-xs text-muted-foreground">
                    <time dateTime={conversion.created_at}>
                      {new Date(conversion.created_at).toLocaleDateString()} at{' '}
                      {new Date(conversion.created_at).toLocaleTimeString()}
                    </time>
                  </div>
                </div>
              </div>
              <Badge variant="secondary">
                Upgrade
              </Badge>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

interface ActivityEvent {
  id: string;
  date: string;
  created_at: string;
  customer: {
    email?: string;
    id: string;
  };
  type: string;
  views: number;
  starts: number;
  converts: number;
  bounces: number;
  flow?: {
    id: string;
  };
  variant_name?: string;
  paywall?: {
    workflow: string;
  };
  latest_event?: string;
}

interface ActivityEventsResponse {
  data: ActivityEvent[];
}

function StatusBadge({ status }: { status: string }) {
  switch (status.toLowerCase()) {
    case 'active':
      return <Badge variant="default">{status}</Badge>;
    case 'pending':
      return <Badge variant="secondary">{status}</Badge>;
    case 'failed':
      return <Badge variant="destructive">{status}</Badge>;
    default:
      return <Badge variant="outline">{status}</Badge>;
  }
}

function CustomLink({ to, children, ...props }: { to: string; children: React.ReactNode } & Omit<React.ComponentPropsWithoutRef<typeof Link>, 'to'>) {
  return (
    <Link
      to={to}
      className="text-primary hover:underline"
      {...props}
    >
      {children}
    </Link>
  );
}

function RecentActivityComponent() {
  const { data: activityData } = useSWR<ActivityResponse>('/dashboard/activity', getFetcher);
  const activities = activityData?.data || [];

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>User Info</TableHead>
            <TableHead>Trigger</TableHead>
            <TableHead>Variant</TableHead>
            <TableHead>Date</TableHead>
            <TableHead>Event</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {activities.map((activity) => (
            <TableRow key={activity.id}>
              <TableCell>
                <div className="flex items-center gap-2">
                  <div className="h-8 w-8 rounded-full bg-muted flex items-center justify-center text-sm font-medium">
                    {activity.customer?.email?.[0]?.toUpperCase() || '?'}
                  </div>
                  <div className="font-medium">
                    {activity.customer?.email || activity.customer?.id}
                  </div>
                </div>
              </TableCell>
              <TableCell>{activity.flow?.id}</TableCell>
              <TableCell>
                <CustomLink to={`/convert/workflows/${activity.paywall?.workflow}`}>
                  {activity.variant_name}
                </CustomLink>
              </TableCell>
              <TableCell>
                <div className="text-sm">
                  {new Date(activity.created_at).toLocaleDateString()} at{' '}
                  {new Date(activity.created_at).toLocaleTimeString()}
                </div>
              </TableCell>
              <TableCell>
                <StatusBadge status={activity.latest_event || 'unknown'} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function truncateKey(key: string) {
  if (!key) return '';
  if (key.length > 20) {
    return `${key.slice(0, 20)}...`;
  }
  return key;
}

// Update the base64url encoding function to properly handle equals signs and special characters
const b64UrlEncode = (obj: object) => {
  // Convert the object to a JSON string
  const jsonStr = JSON.stringify(obj);
  
  // Convert to base64 using btoa
  const base64 = btoa(jsonStr)
    // Convert base64 to base64url by replacing characters
    .replace(/\+/g, '-')    // Convert '+' to '-'
    .replace(/\//g, '_')    // Convert '/' to '_'
    .replace(/=+$/, '');    // Remove trailing '=' characters
  
  return base64;
};

// Update function signature to include clientSecret
const generateSampleJwt = (clientId: string, clientSecret: string, customerId: string = 'cust_123') => {
  try {
    // Create header and payload
    const header = {
      alg: 'HS256',
      typ: 'JWT',
      kid: clientId
    };
    
    const payload = {
      sub: customerId  // Just the customer ID is enough
    };

    // Base64Url encode header and payload
    const encodedHeader = b64UrlEncode(header);
    const encodedPayload = b64UrlEncode(payload);
    
    // Create real signature using HMAC-SHA256
    const textEncoder = new TextEncoder();
    const data = textEncoder.encode(`${encodedHeader}.${encodedPayload}`);
    const key = textEncoder.encode(clientSecret); // Use passed in secret

    // Use Web Crypto API to create proper HMAC signature
    return crypto.subtle.importKey(
      'raw',
      key,
      { name: 'HMAC', hash: 'SHA-256' },
      false,
      ['sign']
    ).then(cryptoKey => 
      crypto.subtle.sign(
        'HMAC',
        cryptoKey,
        data
      )
    ).then(signature => {
      const signatureBase64 = btoa(String.fromCharCode(...new Uint8Array(signature)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
      
      return `${encodedHeader}.${encodedPayload}.${signatureBase64}`;
    });
  } catch (err) {
    console.error(err);
    return Promise.resolve('eyJ0...'); // Fallback
  }
};

function OnboardingSteps() {
  const { data: stripeConnections } = useSWR('/billing/providers', getFetcher, {
    // Poll every 5 seconds while importing
    refreshInterval: (data) => {
      // Check if any connection is in 'importing' state
      const hasImportingConnection = data?.data?.some(
        conn => conn.current_state === 'importing'
      );
      return hasImportingConnection ? 5000 : 0;
    }
  });
  const { user } = useAuth();
  const { data: clients } = useSWR('/clients', getFetcher);
  const [scriptInstalled, setScriptInstalled] = useState(false);
  const [polling, setPolling] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('javascript');
  const [environment, setEnvironment] = useState('live');

    // Update the client filtering based on environment
    const webClient = clients?.data?.find(client => 
      client.type === 'web' && client.environment === environment
    );

  const { data: activityData } = useSWR(
    polling ? `/account/agents` : null, 
    getFetcher,
    { refreshInterval: polling ? 5000 : 0 }
  );

  const hasEvents = activityData && activityData.data.length > 0;

  const stripeConnection = stripeConnections?.data?.find(conn => 
    conn.type === 'stripe_test' || conn.type === 'stripe'
  );
  const stripeConnected = ['success', 'importing'].includes(stripeConnection?.current_state);

  const getClientSecret = () => {
    if (!webClient) return '<CLIENT_SECRET>';
    return webClient.secret;
  };

  // First add the getter for client ID
  const getClientId = () => {
    if (!webClient) return '<CLIENT_ID>';
    return webClient.id;
  };
  // Update the JWT examples to include kid:
  const jwtExamples = {
    javascript: `const jwt = require('jsonwebtoken');

const key = '${getClientSecret()}';
const payload = {
  customer: 'cust_123', // Customer ID from your system
};

const token = jwt.sign(payload, key, { 
  algorithm: 'HS256',
  header: {
    kid: '${getClientId()}' // Client ID as Key ID
  }
});`,

    php: `<?php
require_once 'vendor/autoload.php';
use Firebase\\JWT\\JWT;

$key = '${getClientSecret()}';
$payload = [
    'customer' => 'cust_123', // Customer ID from your system
];

$token = JWT::encode($payload, $key, 'HS256', '${getClientId()}'); // Pass Client ID as Key ID`,

    python: `import jwt
from datetime import datetime, timedelta

key = '${getClientSecret()}'
payload = {
    'customer': 'cust_123', # Customer ID from your system
}

headers = {
    'kid': '${getClientId()}' # Client ID as Key ID
}

token = jwt.encode(payload, key, algorithm='HS256', headers=headers)`
  };

  // Add state for the JWT
  const [jwt, setJwt] = useState('eyJ0...');

  // Add effect to generate JWT when client changes
  useEffect(() => {
    if (webClient) {
      generateSampleJwt(getClientId(), getClientSecret())
        .then(setJwt)
        .catch(() => setJwt('eyJ0...'));
    }
  }, [webClient]);

  // Update the script code to use the state value
  const scriptCode = `<!-- Add to your <head> tag -->
<script src="https://js.plandalf.com/v1.js"></script>
<script>
  // Replace this with a freshly generated JWT for each user
  const jwt = '${jwt}';
  window.plandalf = Plandalf(jwt);
</script>`;

  // Add step tracking
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);

  // Helper to mark step as complete
  const completeStep = (step: number) => {
    if (!completedSteps.includes(step)) {
      setCompletedSteps([...completedSteps, step]);
    }
    setCurrentStep(step + 1);
  };

  // Effect 1: Handle Stripe connection step
  useEffect(() => {
    if (stripeConnected && !completedSteps.includes(1)) {
      completeStep(1);
    }
  }, [stripeConnected, completedSteps]);

  // Effect 2: Handle events detection step
  useEffect(() => {
    if (hasEvents && !completedSteps.includes(4)) {
      completeStep(4);
    }
  }, [hasEvents, completedSteps]);

  // Effect 3: Handle polling state
  useEffect(() => {
    setPolling(!hasEvents);
  }, []);

  const renderStep = (stepNumber: number, title: string, children: React.ReactNode) => (
    <div className="group relative">
      {/* Step connector line - now for all steps */}
      {stepNumber < 5 && (
        <div className="absolute left-[15px] top-[32px] w-[2px] h-[calc(100%+32px)] bg-gray-200 -z-10" />
      )}
      
      <div className="flex gap-4">
        <div className={cx(
          "rounded-full h-8 w-8 flex items-center justify-center text-sm font-semibold shrink-0 relative z-10 transition-colors bg-gray-100 text-gray-900",
        )}>
          {stepNumber}
        </div>
        <div className="flex-1 min-w-0 pt-1">
          <h3 className="text-base font-semibold text-gray-900 mb-3">
            {title}
          </h3>
          <div className="ml-0">
            {children}
          </div>
        </div>
      </div>
    </div>
  );

  const codeBlockStyle = {
    background: 'transparent',
    margin: 0,
    padding: 0,
    fontSize: '12px',
    lineHeight: '1.5',
  };

  const handleContinueToDashboard = () => {
    apiClient.patch(`/organizations/${user.current_organization.id}`, {
      flags: {
        has_completed_onboarding: true
      }
    }).then(() => {
      window.location.href = '/convert/home';
    });
  };

  const { plandalf } = usePlandalf();



  // First, let's add some base styles for the step layout
  const stepContentStyle = "grid grid-cols-2 gap-6 items-start";
  const stepInstructionStyle = "text-sm text-gray-800 space-y-3"; // Smaller text
  const stepCodeStyle = "space-y-3";

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-1 py-8">
        <div className="max-w-4xl mx-auto px-4 space-y-8">
          {/* Header */}
          <div className="text-center space-y-2 pt-4">
            <div className="inline-flex items-center gap-2 px-2.5 py-1 rounded-full bg-blue-50 text-blue-700 text-xs font-medium">
              <Loader2 className="w-3 h-3 animate-spin" />
              Setting up your analytics
            </div>
            <h1 className="text-2xl font-bold text-gray-900">
              Let's get you started
            </h1>
            <p className="text-gray-800 max-w-md mx-auto">
              Complete these quick steps to start tracking your revenue
            </p>
          </div>

          {/* Steps */}
          <div className="space-y-16">
            {/* Step 1: Connect Stripe */}
            {renderStep(1, "Connect your Stripe account", (
              <div className="flex gap-6 rounded-lg p-2.5 bg-gray-100">
                <div className={cx(
                  "rounded-full h-10 w-10 flex items-center justify-center text-base font-semibold transition-colors mt-1",
                  {
                    "bg-gray-100 text-gray-900 group-hover:bg-gray-200": !stripeConnected,
                    "bg-green-100/70 text-green-800": stripeConnected && stripeConnection?.current_state !== 'importing',
                    "bg-blue-100/70 text-blue-800": stripeConnection?.current_state === 'importing'
                  }
                )}>
                  {stripeConnection?.current_state === 'importing' ? (
                    <Loader2 className="w-5 h-5 animate-spin"/>
                  ) : stripeConnected ? (
                    <Check className="w-5 h-5"/>
                  ) : (
                    <LinkIcon className="w-5 h-5"/>
                  )}
                </div>
                <div className="flex-1 min-w-0 space-y-4">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 mb-1">
                      {stripeConnection?.current_state === 'importing' ? (
                        <span className="flex items-center gap-2">
                          Importing Stripe Data
                          <Badge color="blue" variant="soft">
                            <span className="flex items-center gap-1.5">
                              <div className="w-1.5 h-1.5 rounded-full bg-blue-500 animate-pulse"/>
                              Importing
                            </span>
                          </Badge>
                        </span>
                      ) : stripeConnected ? (
                        <span className="flex items-center gap-2">
                          Connected to Stripe
                          <Badge color="green" variant="soft">
                            <span className="flex items-center gap-1.5">
                              <div className="w-1.5 h-1.5 rounded-full bg-green-500 animate-pulse"/>
                              Active
                            </span>
                          </Badge>
                        </span>
                      ) : (
                        "Connect your Stripe account"
                      )}
                    </h3>
                    {stripeConnected ? (
                      <div className="space-y-2">
                        <p className="text-gray-800">
                          Your Stripe account is connected and ready to process payments
                        </p>
                        <div className="flex items-center gap-3 text-sm">
                          <div className="flex items-center gap-1.5 text-gray-800">
                            <img 
                              src={stripeConnection?.integration.icon_url || "https://stripe.com/favicon.ico"}
                              alt="Stripe"
                              className="w-4 h-4 rounded"
                            />
                            {stripeConnection?.name}
                          </div>
                          {stripeConnection?.external_url && (
                            <Link 
                              href={stripeConnection.external_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="inline-flex items-center gap-1.5 text-blue-600 hover:text-blue-700 font-medium"
                            >
                              View in Stripe
                              <ExternalLink className="w-3.5 h-3.5" />
                            </Link>
                          )}
                        </div>
                      </div>
                    ) : (
                      <p className="text-gray-700">
                        Link your Stripe account to start analyzing your revenue data
                      </p>
                    )}
                  </div>

                  {/* Operations list moved inside */}
                  {stripeConnection?.operations?.length > 0 && (
                    <div className="space-y-3 border-t border-gray-200 pt-4 mt-4">
                      <table className="w-full text-left">
                        <tbody>
                        {stripeConnection.operations.map((operation, index) => (
                          <tr key={index} className="border-b border-gray-200/50 last:border-0">
                            <td className="py-2" valign="top">
                              <div className="font-medium text-sm">{operation.name}</div>
                              <div className="text-gray-600 text-xs">{operation.description}</div>
                            </td>

                            <td className="py-2" valign="top">
                              <div className="flex gap-2">
                                <div className={cx({
                                  "flex items-center text-xs px-2 py-0.5 font-medium rounded-full": true,
                                  "text-green-500": operation.status === "completed",
                                  "text-red-500": operation.status === "failed",
                                  "bg-blue-100 text-blue-700": operation.status === "running",
                                  "text-gray-500": operation.status === "pending",
                                  "text-purple-500": operation.status === "queued",
                                  "text-purple-500": operation.status === "retrying",
                                  "bg-gray-500 text-gray-200": operation.status === "cancelled"
                                })}>
                                  {operation.status}
                                </div>
                                {operation.meta && (
                                  <span className="text-xs text-gray-600">
                                    ({(operation.meta.total_jobs - operation.meta.pending_jobs)}/{operation.meta.total_jobs})
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="py-2 text-xs" valign="top">
                              {operation.metadata && (
                                <div className="leading-snug text-gray-600">
                                  {Object.keys(operation?.metadata).map((key, index) => (
                                    <div key={index}>
                                      <span>{key}:</span>{' '}
                                      <span className="text-blue-600 font-medium">{operation.metadata[key]}</span>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </td>
                            <td className="py-2 text-xs" valign="top">
                              {(operation.status === 'running' || operation.status === 'failed') && (
                                <div className="text-gray-600">
                                  {operation.runtime} seconds total runtime
                                  {(operation.runtime > 300 || operation.meta.failed_jobs > 0) && (
                                    <div className="mt-1">
                                      <Link 
                                        href={`/settings/connections/${stripeConnection.id}`}
                                        className="inline-flex items-center gap-1.5 text-red-600 hover:text-red-700 font-medium text-xs"
                                      >
                                        View Details
                                        <ExternalLink className="w-3 h-3" />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              )}
                              {operation.status === 'finished' && (
                                <Link 
                                  href={`/settings/connections/${stripeConnection.id}`}
                                  className="inline-flex items-center gap-1.5 text-green-600 hover:text-green-700 font-medium text-xs"
                                >
                                  View Details
                                  <ExternalLink className="w-3 h-3" />
                                </Link>
                              )}
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <Button 
                  size="3"
                  disabled={stripeConnected}
                  onClick={() => window.location.href = '/settings/integrations/create?provider=stripe&redirect_uri=/convert/home'}
                  className={cx(
                    "shrink-0 transition-all duration-200 font-medium",
                    {
                      "opacity-0": stripeConnected,
                      "bg-[#008DA7] hover:bg-[#0A7C91] active:bg-[#0A7C91]": !stripeConnected
                    }
                  )}
                >
                  {stripeConnected ? 'Connected' : 'Connect Stripe'}
                </Button>
              </div>
            ))}

            {/* Step 2: Get your Client Secret */}
            {renderStep(2, "Get your Client Secret", (
              <div className={stepContentStyle}>
                {/* Left: Instructions */}
                <div className={stepInstructionStyle}>
                  <p>Get your client secret to authenticate your requests. Choose the appropriate environment:</p>

                  <div className="p-2.5 bg-yellow-50">
                    <div className="flex gap-2">
                      <div className="p-1">⚠️</div>
                      <div className="text-yellow-800">
                        <strong>Environment Note:</strong> Use test client secrets for development/staging environments. 
                        For production, create a new client in live mode from the <Link href="/settings/clients" className="text-blue-600 hover:underline">clients page</Link>.
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right: Interactive/Code Content */}
                <div className={stepCodeStyle}>
                  {/* Environment selector */}
                  <div className="flex items-center gap-3 mb-4">
                    <div className="text-sm font-medium text-gray-700">Environment:</div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => setEnvironment('test')}
                        className={cx(
                          "px-3 py-1 text-sm font-medium rounded-md transition-colors",
                          {
                            "bg-blue-100 text-blue-700": environment === 'test',
                            "bg-gray-100 text-gray-800 hover:bg-gray-200": environment !== 'test'
                          }
                        )}
                      >
                        Test Mode
                      </button>
                      <button
                        onClick={() => setEnvironment('live')}
                        className={cx(
                          "px-3 py-1 text-sm font-medium rounded-md transition-colors",
                          {
                            "bg-blue-100 text-blue-700": environment === 'live',
                            "bg-gray-100 text-gray-800 hover:bg-gray-200": environment !== 'live'
                          }
                        )}
                      >
                        Live Mode
                      </button>
                    </div>
                  </div>

                  {/* Environment Variables Display */}
                  <div className="space-y-3">
                    {/* Environment Variables Code Block */}
                    <div className="relative">
                      <div className="absolute -inset-px bg-gradient-to-r from-[#008DA7] to-[#0A7C91] rounded-lg opacity-75 group-hover:opacity-100 transition-opacity" />
                      <div className="relative bg-gray-950 rounded-lg overflow-hidden">
                        <div className="flex items-center justify-between px-4 py-2 bg-gray-900/50">
                          <span className="text-sm text-gray-400 font-medium">
                            .env
                          </span>
                          <Button
                            size="1"
                            onClick={() => {
                              const envVars = `PLANDALF_CLIENT_ID=${getClientId()}\nPLANDALF_CLIENT_SECRET=${getClientSecret()}`;
                              navigator.clipboard.writeText(envVars);
                              toast.success('Environment variables copied to clipboard');
                            }}
                            className="bg-white/10 hover:bg-white/20 transition-colors"
                          >
                            <span className="flex items-center gap-1.5 text-sm">
                              <ClipboardIcon className="w-3.5 h-3.5" />
                              Copy
                            </span>
                          </Button>
                        </div>
                        <div className="p-4">
                          <SyntaxHighlighter
                            language="bash"
                            style={vscDarkPlus}
                            customStyle={codeBlockStyle}
                          >
                            {`PLANDALF_CLIENT_ID=${getClientId()}\nPLANDALF_CLIENT_SECRET=${getClientSecret()}`}
                          </SyntaxHighlighter>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Step 3: Generate JWT Token */}
            {renderStep(3, "Generate JWT Token", (
              <div className={stepContentStyle}>
                {/* Left: Instructions */}
                <div className={stepInstructionStyle}>
                  <p>Generate a JWT token to authenticate your customers:</p>
                  <ul className="list-disc pl-4 space-y-1 ">
                    <li>Create a new token for each customer session</li>
                    <li>Include the customer ID in the <code className="text-xs bg-gray-100 px-1 rounded">sub</code> claim</li>
                    <li>Set an expiration time to limit token validity</li>
                  </ul>
                </div>

                {/* Right: Code Examples */}
                <div className={stepCodeStyle}>
                  {/* Language selector and code examples */}
                  <div className="flex items-center gap-2">
                    {['javascript', 'php', 'python'].map((lang) => (
                      <button
                        key={lang}
                        onClick={() => setSelectedLanguage(lang)}
                        className={cx(
                          "px-2.5 py-1 text-xs font-medium rounded-md transition-colors",
                          {
                            "bg-blue-100 text-blue-700": selectedLanguage === lang,
                            "bg-gray-100 text-gray-800 hover:bg-gray-200": selectedLanguage !== lang
                          }
                        )}
                      >
                        {lang === 'javascript' ? 'Node.js' : lang.charAt(0).toUpperCase() + lang.slice(1)}
                      </button>
                    ))}
                  </div>

                  {/* JWT Generation Example */}
                  <div className="relative">
                    <div className="absolute -inset-px bg-gradient-to-r from-[#008DA7] to-[#0A7C91] rounded-lg opacity-75 group-hover:opacity-100 transition-opacity" />
                    <div className="relative bg-gray-950 rounded-lg overflow-hidden">
                      <div className="flex items-center justify-between px-4 py-2 bg-gray-900/80">
                        <span className="text-sm text-gray-400 font-medium">
                          Generate JWT Token
                        </span>
                        <Button
                          size="1"
                          onClick={() => {
                            navigator.clipboard.writeText(jwtExamples[selectedLanguage]);
                            toast.success('Code copied to clipboard');
                          }}
                          className="bg-white/10 hover:bg-white/20 transition-colors"
                        >
                          <span className="flex items-center gap-1.5 text-sm">
                            <ClipboardIcon className="w-3.5 h-3.5" />
                            Copy code
                          </span>
                        </Button>
                      </div>
                      <div className="p-4 bg-gray-950">
                        <SyntaxHighlighter
                          language={selectedLanguage}
                          style={vscDarkPlus}
                          customStyle={codeBlockStyle}
                        >
                          {jwtExamples[selectedLanguage]}
                        </SyntaxHighlighter>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Step 4: Install Script */}
            {renderStep(4, "Install the script", (
              <div className={stepContentStyle}>
                {/* Left: Instructions */}
                <div className={stepInstructionStyle}>
                  <p>Add our script to your website's <code className="text-blue-600 bg-blue-50 px-1.5 py-0.5 rounded-md text-sm font-mono">&lt;head&gt;</code> tag</p>
                  
                  <div className="p-2.5 bg-yellow-50 border border-yellow-200 rounded-lg text-xs mt-4">
                    <div className="flex gap-2">
                      <div className="p-1">⚠️</div>
                      <div className="text-yellow-800">
                        <strong>Note:</strong> Make sure to replace the JWT token with a freshly generated one for each user session.
                        See the JWT generation example above.
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right: Code */}
                <div className={stepCodeStyle}>
                  <div className="relative">
                    <div className="absolute -inset-px bg-gradient-to-r from-[#008DA7] to-[#0A7C91] rounded-lg opacity-75 group-hover:opacity-100 transition-opacity" />
                    <div className="relative bg-gray-950 rounded-lg overflow-hidden">
                      <div className="flex items-center justify-between px-4 py-2 bg-gray-900/50">
                        <span className="text-sm text-gray-400 font-medium">
                          Installation
                        </span>
                        <Button
                          size="1"
                          onClick={() => {
                            navigator.clipboard.writeText(scriptCode.trim());
                            toast.success('Script copied to clipboard', {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                            });
                          }}
                          className="bg-white/10 hover:bg-white/20 transition-colors"
                        >
                          <span className="flex items-center gap-1.5 text-sm">
                            <ClipboardIcon className="w-3.5 h-3.5" />
                            Copy code
                          </span>
                        </Button>
                      </div>
                      <div className="p-4">
                        <SyntaxHighlighter
                          language="html"
                          style={vscDarkPlus}
                          customStyle={codeBlockStyle}
                        >
                          {scriptCode}
                        </SyntaxHighlighter>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Step 5: Verify Installation */}
            {renderStep(5, "Verify installation", (
              <div className="space-y-4">
                {polling && !hasEvents ? (
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-3 p-4 bg-blue-50 text-blue-700 rounded-lg">
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <div>
                        <p className="font-medium">Waiting for first event...</p>
                        <p className="text-sm text-blue-600">This usually takes about 30 seconds</p>
                      </div>
                    </div>
                    
                    <div className="p-4 bg-gray-50 rounded-lg space-y-3">
                      <h4 className="font-medium text-gray-900">While you wait:</h4>
                      <ul className="space-y-2 text-sm text-gray-700">
                        <li className="flex items-start gap-2">
                          <div className="mt-1 w-1.5 h-1.5 rounded-full bg-gray-400"/>
                          Make sure you've installed the script in your website's <code className="text-xs bg-gray-100 px-1 rounded">&lt;head&gt;</code> tag
                        </li>
                        <li className="flex items-start gap-2">
                          <div className="mt-1 w-1.5 h-1.5 rounded-full bg-gray-400"/>
                          Verify that you're generating a valid JWT token for your users
                        </li>
                        <li className="flex items-start gap-2">
                          <div className="mt-1 w-1.5 h-1.5 rounded-full bg-gray-400"/>
                          Test your integration by visiting your website and triggering some events
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : hasEvents ? (
                  <div className="space-y-4">
                    <div className="flex items-center gap-3 p-4 bg-green-50 text-green-700 rounded-lg">
                    
                      <div>
                        <p className="font-medium">Installation verified!</p>
                        <p className="text-sm text-green-600">We're receiving events from your website</p>

                        <div className="flex items-center gap-2"><Check className="w-3 h-3" /> <b>User:</b> {activityData.data[0].lookup_key}</div>
                      </div>
                    </div>
                    
                    <Button 
                      onClick={handleContinueToDashboard}
                      className="w-full"
                    >
                      Continue to Dashboard
                    </Button>
                  </div>
                ) : (
                  <p className="text-gray-700">
                    Copy and install the script above, then wait for verification...
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ConvertHomePage() {
  const { data: chartData } = useSWR<ChartData>('/dashboard/chart-data', getFetcher);
  const { data: conversionData } = useSWR<{ data: Conversion[] }>('/dashboard/conversions', getFetcher);
  const { data: activityData } = useSWR<ActivityResponse>('/dashboard/activity', getFetcher);

  const conversions = conversionData?.data || [];
  const activities = activityData?.data || [];

  return (
    <div className="p-10 space-y-8">
      <div className="flex items-center justify-between">
        <H1>Your Overview</H1>
        <Button variant="outline" asChild>
          <Link to="/account/signals" className="gap-2">
            <RadioTower className="h-4 w-4" />
            Signals
          </Link>
        </Button>
      </div>

      <OverviewGridComponent/>

      <div className="grid grid-cols-3 gap-8">
        <RevenueTrendComponent />
        <ActivityComponent />
        <ConversionsListComponent />
      </div>

      <Card>
        <CardHeader>
          <H2>Recent Activity</H2>
        </CardHeader>
        <CardContent>
          <RecentActivityComponent />
        </CardContent>
      </Card>
    </div>
  )
}
