import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import { Workflow, Handler } from "~/types";
import { WorkflowEditRequest } from "~/types/api/workflow";
import HandlerForm from "~/components/workflow/HandlerForm";
import Alert from "~/components/Alert";
import {toast} from "react-toastify";
import { Input } from "~/components/ui/input";
import { Button } from "~/components/ui/button";
import { H1, H2, H3, P } from "~/components/ui/typography";
import { PlusIcon } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "~/components/ui/dialog";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "~/components/ui/breadcrumb";

const DEFAULT_HANDLER: Handler = {
  listen: 'attachment',
  event_name: 'click',
  qualifier: 'findButtonByText',
  props: {
    text: '',
  },
};

export default function WorkflowEditPage() {
  const {workflowId} = useParams();
  const {data: workflow, error: workflowError, isLoading} = useSWR(`/intel/flows/${workflowId}`, getFetcher<Workflow>);
  const [errors, setErrors] = useState<Record<string, [string]> | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState<WorkflowEditRequest | null>(null);

  useEffect(() => {
    if (!workflow) return;
    setData({
      name: workflow.name,
      lookup_key: workflow.id.toString(),
      handlers: workflow.handlers,
      images: workflow.images,
    });
  }, [workflow]);

  const handleHandlerChange = (index: number, handler: Handler) => {
    if (!data) return;
    const newHandlers = [...data.handlers];
    newHandlers[index] = handler;
    setData({ ...data, handlers: newHandlers });
  };

  const handleHandlerDelete = (index: number) => {
    if (!data) return;
    const newHandlers = [...data.handlers];
    newHandlers.splice(index, 1);
    setData({ ...data, handlers: newHandlers });
  };

  const addHandler = () => {
    if (!data) return;
    setData({ ...data, handlers: [...data.handlers, { ...DEFAULT_HANDLER }] });
  };

  const handleDelete = async () => {
    try {
      const response = await apiClient.delete(`/intel/flows/${workflowId}`);
      if (response.status === 200 || response.status === 204) {
        toast.success('Workflow deleted successfully');
        navigate('/convert/workflows');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to delete workflow';
      toast.error(errorMessage);
      setIsDeleteDialogOpen(false);
    }
  };

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (!data) return;
    setErrors(null);
    setIsSaving(true);

    const newData = {
      ...data,
      id: data.lookup_key,
      images: data.images?.map((image) => image.id).filter((id) => id !== null)
    }

    apiClient.put(`/intel/flows/${workflowId}`, newData)
      .then(res => {
        toast.success('Workflow updated');
        navigate(`/convert/workflows/${res.data.id}`);
      })
      .catch(err => {
        setIsSaving(false);
        if (err.response?.status === 422) {
          const responseErrors = err.response.data.errors;
          const formattedErrors: Record<string, [string]> = {};
          Object.entries(responseErrors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              formattedErrors[key] = [value[0]];
            } else if (typeof value === 'object') {
              Object.entries(value as Record<string, string[]>).forEach(([nestedKey, nestedValue]) => {
                formattedErrors[`${key}.${nestedKey}`] = [nestedValue[0]];
              });
            }
          });
          setErrors(formattedErrors);
        } else {
          setErrors({ general: [err.response?.data?.message || 'An error occurred'] });
        }
      })
  }

  if (isLoading) return <div>Loading...</div>;
  if (!data) return null;

  return (
    <div className="p-10">
      <div className="mb-8">
        <div className="flex justify-between items-center">
          <div>
            <H1>Edit Workflow</H1>
            <P className="text-muted-foreground text-lg mt-3">Update your workflow configuration and handlers</P>
          </div>
          <Button variant="destructive" onClick={() => setIsDeleteDialogOpen(true)}>
            Delete Workflow
          </Button>
        </div>
      </div>

      <Breadcrumb className="mb-8">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/convert">Upgrade & Checkout</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink href={`/convert/workflows/${workflow.id}`}>{workflow.name}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Edit</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <form onSubmit={handleSubmit} className="space-y-6">
        {errors?.general && (
          <Alert title="Error">
            {errors.general[0]}
          </Alert>
        )}

        <div className="bg-card rounded-lg border shadow-sm">
          <div className="p-6">
            <div className="grid grid-cols-2 gap-8">
              <div className="space-y-3">
                <label htmlFor="name" className="text-sm font-medium">Name</label>
                <Input
                  id="name"
                  placeholder="Enter workflow name"
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  aria-invalid={!!errors?.name}
                />
                {errors?.name && (
                  <p className="text-sm text-destructive">{errors.name[0]}</p>
                )}
              </div>

              <div className="space-y-3">
                <label htmlFor="lookup_key" className="text-sm font-medium">Lookup Key</label>
                <Input
                  id="lookup_key"
                  placeholder="Enter lookup key"
                  value={data.lookup_key}
                  onChange={(e) => setData({ ...data, lookup_key: e.target.value })}
                  aria-invalid={!!errors?.lookup_key}
                />
                {errors?.lookup_key && (
                  <p className="text-sm text-destructive">{errors.lookup_key[0]}</p>
                )}
              </div>
            </div>
          </div>

          <div className="border-b p-6">
            <div className="flex justify-between items-center">
              <div>
                <H3>Workflow Handlers</H3>
                <P className="text-muted-foreground text-sm mt-1">Define when this workflow should be triggered</P>
              </div>
              {data.handlers.length > 0 && (
                <Button 
                  onClick={addHandler}
                  variant="outline"
                  size="sm"
                >
                  <PlusIcon className="w-4 h-4 mr-2" />
                  Add Handler
                </Button>
              )}
            </div>
          </div>

          <div className="p-6">
            {data.handlers.length === 0 ? (
              <div className="flex flex-col items-center justify-center py-16">
                <div className="mb-4 rounded-full bg-muted p-3">
                  <PlusIcon className="w-6 h-6 text-muted-foreground" />
                </div>
                <h3 className="text-lg font-semibold mb-2">No handlers configured</h3>
                <P className="text-muted-foreground mb-6">Add your first handler to define when this workflow should trigger</P>
                <Button 
                  onClick={addHandler}
                  variant="default"
                >
                  <PlusIcon className="w-4 h-4 mr-2" />
                  Add your first handler
                </Button>
              </div>
            ) : (
              <div className="space-y-4">
                {data.handlers.map((handler, index) => {
                  const handlerErrors = errors ? 
                    Object.entries(errors)
                      .filter(([key]) => key.startsWith(`handlers.${index}.`))
                      .reduce((acc, [key, value]) => {
                        const fieldName = key.split('.')[2];
                        acc[fieldName] = value;
                        return acc;
                      }, {} as Record<string, [string]>) 
                    : undefined;

                  return (
                    <HandlerForm
                      key={index}
                      handler={handler}
                      onChange={(handler) => handleHandlerChange(index, handler)}
                      onDelete={() => handleHandlerDelete(index)}
                      errors={handlerErrors}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center justify-end space-x-4 pt-6">
          <Button 
            type="button"
            variant="outline"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button 
            type="submit"
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Save Changes'}
          </Button>
        </div>
      </form>

      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Workflow</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this workflow? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDeleteDialogOpen(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleDelete}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
