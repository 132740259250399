import React, { useState, useEffect, useRef } from 'react';
import WorkflowLayout from "~/pages/convert/workflows/WorkflowLayout";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import { formatDistanceToNow } from 'date-fns';
import { mutate } from 'swr';
import cx from 'classnames';
import { Trash, X } from "lucide-react";
import { Button } from "~/components/ui/button";
import { Badge } from "~/components/ui/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "~/components/ui/dialog";
import { toast } from "react-toastify";

interface ActivityAction {
  id: string;
  event_name: string;
  type: string;
  datetime: string;
  properties: Record<string, any>;
  metadata: Record<string, any>;
}

interface ActivitySession {
  id: string;
  user_name: string;
  customer_id?: string;
  customer_email?: string;
  country_flag: string;
  placement: string;
  variant_name: string;
  total_views_count: number;
  last_interaction_at: number;
  latest_event: string;
  is_excluded: boolean;
  actions: ActivityAction[];
  country_name: string;
  browser_name?: string;
  os_name?: string;
}

interface ActivityEvent {
  type: string;
  created_at: number;
}

interface ActivityDetails {
  events: ActivityEvent[];
}

interface ActivityCollection {
  data: ActivitySession[];
  meta: {
    current_page: number;
    per_page: number;
  };
  links: {
    next?: string;
    prev?: string;
  };
}

function exclusionReason(reason: string) {
  switch (reason) {
    case 'manual':
      return 'Manually excluded';
    case 'client_exclusion':
      return 'Client Rule';
    default:
      return 'no reason';
  }
}

function ParticipantList({ workflow }) {
  const navigate = useNavigate();
  const location = useLocation();
  const pageSize = 10;

  const initialPage = new URLSearchParams(location.search).get('page');
  const [page, setPage] = useState(initialPage ? parseInt(initialPage) : 1);
  const [selectedSession, setSelectedSession] = useState<ActivitySession | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {                       
    data: collection,
    error
  } = useSWR<ActivityCollection>(`/intel/flows/${workflow.id}/activities?page=${page}&pageSize=${pageSize}`, getFetcher);
  
  useEffect(() => {
    navigate(`${location.pathname}?page=${page}`, { replace: true });
  }, [page, navigate, location.pathname]);

  const [savingId, setSavingId] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [confirmingDeleteId, setConfirmingDeleteId] = useState<string | null>(null);

  const handleDeleteClick = (e: React.MouseEvent, sessionId: string) => {
    e.stopPropagation();
    setConfirmingDeleteId(sessionId);
  };

  const handleConfirmDelete = async (e: React.MouseEvent, sessionId: string) => {
    e.stopPropagation();
    setDeletingId(sessionId);
    try {
      await apiClient.delete(`/intel/flows/${workflow.id}/activities/${sessionId}`);
      toast.success("Session deleted successfully");
      mutate(`/intel/flows/${workflow.id}/activities?page=${page}&pageSize=${pageSize}`);
    } catch (error) {
      toast.error("Failed to delete session");
    } finally {
      setDeletingId(null);
      setConfirmingDeleteId(null);
    }
  };

  const handleCancelDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    setConfirmingDeleteId(null);
  };

  function dateFormat(time: number) {
    return formatDistanceToNow(time*1000, { addSuffix: true });
  }

  const handlePrevPage = () => setPage(prev => Math.max(prev - 1, 1));
  const handleNextPage = () => setPage(prev => prev + 1);

  const handleRowClick = (session) => {
    setSelectedSession(session);
    setIsModalOpen(true);
  };

  return (
    <div className="space-y-6 pt-8">
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>User Info</TableHead>
              <TableHead>Trigger</TableHead>
              <TableHead>Variant</TableHead>
              <TableHead>Total Views</TableHead>
              <TableHead>Last Interaction</TableHead>
              <TableHead>Latest Event</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {collection?.data?.map((session, index) => (
              <TableRow 
                key={index} 
                className={cx(
                  'cursor-pointer hover:bg-accent',
                  {'bg-muted': session.is_excluded}
                )}
                onClick={() => handleRowClick(session)}
              >
                <TableCell>
                  <div className="flex items-center gap-2">
                    <div className="w-6">{session.country_flag}</div>
                    {session.customer_id ? (
                      <Link 
                        to={`/customers/${session.customer_id}`} 
                        className="text-sm font-medium text-primary hover:underline"
                      >
                        {session.customer_email || session.user_name}
                      </Link>
                    ) : (
                      <div>{session.user_name}</div>
                    )}
                  </div>
                  <div className="text-xs text-muted-foreground">{session.id}</div>
                </TableCell>
                <TableCell>{session.placement}</TableCell>
                <TableCell>{session.variant_name}</TableCell>
                <TableCell>{session.total_views_count}</TableCell>
                <TableCell>
                  {session.last_interaction_at ? dateFormat(session.last_interaction_at) : '-'}
                </TableCell>
                <TableCell>
                  <SessionBadge event={session.latest_event} />
                </TableCell>
                <TableCell>
                  {confirmingDeleteId === session.id ? (
                    <div className="flex items-center gap-1">
                      <Button
                        size="icon"
                        variant="ghost"
                        className="h-8 w-8"
                        disabled={deletingId === session.id}
                        onClick={(e) => handleConfirmDelete(e, session.id)}
                      >
                        <Trash className="h-4 w-4" />
                      </Button>
                      <Button
                        size="icon"
                        variant="ghost"
                        className="h-8 w-8"
                        onClick={handleCancelDelete}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  ) : (
                    <Button
                      size="icon"
                      variant="ghost"
                      className="h-8 w-8"
                      disabled={deletingId === session.id}
                      onClick={(e) => handleDeleteClick(e, session.id)}
                    >
                      <Trash className="h-4 w-4" />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Activity Details</DialogTitle>
          </DialogHeader>
          
          {selectedSession && (
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="font-medium text-sm text-muted-foreground">User</h3>
                  <p>{selectedSession.user_name}</p>
                </div>
                <div>
                  <h3 className="font-medium text-sm text-muted-foreground">Location</h3>
                  <p>{selectedSession.country_flag} {selectedSession.country_name}</p>
                </div>
                <div>
                  <h3 className="font-medium text-sm text-muted-foreground">Browser</h3>
                  <p>{selectedSession.browser_name || 'Unknown'}</p>
                </div>
                <div>
                  <h3 className="font-medium text-sm text-muted-foreground">OS</h3>
                  <p>{selectedSession.os_name || 'Unknown'}</p>
                </div>
                <div>
                  <h3 className="font-medium text-sm text-muted-foreground">Trigger</h3>
                  <p>{selectedSession.placement}</p>
                </div>
                <div>
                  <h3 className="font-medium text-sm text-muted-foreground">Variant</h3>
                  <p>{selectedSession.variant_name}</p>
                </div>
              </div>

              <div>
                <h3 className="font-medium text-sm text-muted-foreground mb-2">Activity Timeline</h3>
                <div className="space-y-2">
                  {selectedSession.actions.map((action, index) => (
                    <div key={index} className="flex items-center gap-2 text-sm">
                      <SessionBadge event={action.type} />
                      <span className="text-muted-foreground">
                        {formatDistanceToNow(new Date(action.datetime), { addSuffix: true })}
                      </span>
                      {action.event_name && (
                        <span className="text-muted-foreground">- {action.event_name}</span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <div className="flex justify-between items-center">
        <Button 
          variant="outline"
          onClick={handlePrevPage} 
          disabled={page === 1}
        >
          Previous
        </Button>
        <span className="text-sm text-muted-foreground">Page {page}</span>
        <Button 
          variant="outline"
          onClick={handleNextPage} 
          disabled={!collection?.data || collection.data.length < pageSize}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

const eventNameMap = {
  'start': { name: 'Started', variant: 'secondary' },
  'entry': { name: 'Entry', variant: 'outline' },
  'scene:view': { name: 'Viewed', variant: 'outline' },
};

function SessionBadge({ event }) {
  if (event === 'convert') {
    return <Badge variant="secondary">Conversion!</Badge>;
  }
  if (event?.substring(0, 6) === 'error:') {
    return <Badge variant="destructive">Error</Badge>;
  }
  const config = eventNameMap[event] || { name: event, variant: 'outline' };
  return <Badge variant={config.variant}>{config.name}</Badge>;
}

export default function WorkflowParticipationPage() {
  const params = useParams();

  const {data: workflow, error} = useSWR(
    `/intel/flows/${params.id}`,
    url => apiClient.get(url).then(res => res.data)
  );

  if (!workflow) return null;

  return (
    <WorkflowLayout workflow={workflow}>
      <ParticipantList workflow={workflow} />
    </WorkflowLayout>
  );
}
