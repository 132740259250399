import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const currencySymbols: Record<string, string> = {
  USD: '$', EUR: '€', GBP: '£', JPY: '¥', AUD: 'A$', CAD: 'C$', CHF: 'CHF',
  CNY: '¥', SEK: 'kr', NZD: 'NZ$', CLF: 'CLF', BHD: 'ب.د', IQD: 'ع.د',
  JOD: 'د.ا', KWD: 'د.ك', LYD: 'ل.د', OMR: 'ر.ع', TND: 'د.ت'
};

export const currencyConfig = {
  FOUR_DECIMALS: ['CLF'] as const,
  THREE_DECIMALS: ['BHD', 'IQD', 'JOD', 'KWD', 'LYD', 'OMR', 'TND'] as const,
  ZERO_DECIMALS: [
    'CVE', 'DJF', 'GNF', 'ISK', 'JPY', 'KMF', 'KRW', 'PYG', 'CLP', 'RWF',
    'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF'
  ] as const
} as const;

export function getCurrencySymbol(currency: string): string {
  return currencySymbols[currency] || '$';
}

export function getDecimalPlaces(currency: string): number {
  if (currencyConfig.FOUR_DECIMALS.includes(currency as any)) return 4;
  if (currencyConfig.THREE_DECIMALS.includes(currency as any)) return 3;
  if (currencyConfig.ZERO_DECIMALS.includes(currency as any)) return 0;
  return 2;
}

export function formatCurrency(amount: number | string, currency: string): string {
  const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
  const decimalPlaces = getDecimalPlaces(currency);
  
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(numericAmount);
}

export function formatDate(date: string | Date): string {
  if (!date) return '-';
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(new Date(date));
}

export function formatDateTime(date: string | Date): string {
  if (!date) return '-';
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date));
}

export function formatNumberWithCommas(x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function slugify(text: string): string {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-');
}

export function truncate(str: string, n: number): string {
  return str.length > n ? str.slice(0, n - 1) + '...' : str;
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}