import { Avatar } from "@radix-ui/themes";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import PageHeader from "~/components/PageHeader";
import { ProductFamily } from "~/types";
import { Button } from "~/components/ui/button";
import { Link } from "react-router-dom";
import { H1 } from "~/components/ui/typography";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";

export default function ProductFamiliesIndexPage() {
  const { data: productFamilies, error } = useSWR('/catalog/product_families', getFetcher<{ data: ProductFamily[] }>);

  return (
    <div className="space-y-6 p-10">
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <H1>Product Families</H1>
          <p className="text-sm text-muted-foreground">
            Manage your product families and their associated products
          </p>
        </div>
        <Button variant="outline" asChild>
          <Link to="/catalog/product_families/create">Create New</Link>
        </Button>
      </div>

      {productFamilies && (
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Icon</TableHead>
                <TableHead>Name</TableHead>
                <TableHead>Lookup Key</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {productFamilies.data.map((family) => (
                <TableRow key={family.id}>
                  <TableCell>
                    <Avatar fallback={"IC"} size="2" src={family.icon?.href} />
                  </TableCell>
                  <TableCell>
                    <Link 
                      to={`/catalog/product_families/${family.id}`}
                      className="font-medium text-primary hover:underline"
                    >
                      {family.name}
                    </Link>
                  </TableCell>
                  <TableCell>{family.lookup_key}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
}
