import apiClient, {getFetcher} from "~/lib/apiClient";
import useSWR from "swr";
import PageHeader from "~/components/PageHeader";
import React from "react";
import {PlusIcon} from "@heroicons/react/24/outline";
import { Workflow } from "~/types";
import {Badge, Heading} from "@radix-ui/themes";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { Button } from "~/components/ui/button";
import { H1 } from "~/components/ui/typography";

export default function WorkflowsListPage() {
  const [page, setPage] = React.useState(1);
  const { data: workflows, error, isLoading } = useSWR<any>(`/intel/flows?page=${page}`, getFetcher);

  return (
    <div className="flex flex-col gap-4 p-10">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Analytics</h1>
        <Button asChild>
          <Link to="/convert/workflows/create" className="gap-2">
            <PlusIcon className="w-4 h-4"/>
            Create New
          </Link>
        </Button>
      </div>

      {error && <div>Failed to load</div>}
      {!workflows && <div>Loading...</div>}

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Products</TableHead>
              <TableHead>Views</TableHead>
              <TableHead>Conversions</TableHead>
              <TableHead>Conv Rate</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {workflows?.data?.map(workflow => (
              <TableRow key={workflow.id}>
                <TableCell>
                  <Heading size="2" className="mb-2">
                    <Link to={`/convert/workflows/${workflow.id}`} className="block">{workflow.name || workflow.id}</Link>
                  </Heading>
                  <div className="flex gap-2">
                    {workflow.trigger_descriptors.map(d => <Badge color="gray" key={d}>{d}</Badge>)}
                  </div>
                </TableCell>
                <TableCell>
                  <Badge variant="surface" color={workflow.scenarios_count === 0 ? 'orange' : 'green'}>
                    {workflow.scenarios_count} Active
                  </Badge>
                </TableCell>
                <TableCell>
                  <ul>
                    {workflow.purchasables.map((purchasable, index) => (
                      <li key={index}>
                        <Link to="#">{purchasable.name}</Link>
                      </li>
                    ))}
                  </ul>
                </TableCell>
                <TableCell>
                  {!workflow.stats?.sessions_count && ('-')}
                  {workflow.stats?.sessions_count > 0 && (workflow.stats.sessions_count)}
                </TableCell>
                <TableCell>
                  {!workflow.stats?.conversions_count && ('-')}
                  {workflow.stats?.conversions_count > 0 && (workflow.stats.conversions_count)}
                </TableCell>
                <TableCell>
                  {!workflow.stats?.conversion_rate && ('-')}
                  {workflow.stats?.conversion_rate > 0 && `${workflow.stats.conversion_rate}%`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
