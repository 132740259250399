import { getFetcher } from "~/lib/apiClient";
import useSWR from "swr";
import { useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { H1 } from "~/components/ui/typography";
import { Button } from "~/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { Badge } from "~/components/ui/badge";
import { Tabs, TabsList, TabsTrigger } from "~/components/ui/tabs";
import { Copy, Loader2 } from "lucide-react";
import { cn } from "~/lib/utils";
import { formatDate } from "~/utils/dateTime";

interface Package {
  id: string;
  name: string;
  currencies?: Array<{
    currency_code: string;
    currency_flag: string;
  }>;
  intervals?: Array<{
    label: string;
  }>;
}

interface Plan {
  id: string;
  type: string;
  status: string;
  currency_code: string;
  currency_flag: string;
  renew_interval: string;
  package?: Package;
}

interface Product {
  id: string;
  name: string;
  status: string;
  family: string;
  published_at: string;
  updated_at: string;
  plans_count: number;
}

interface PricingScheme {
  id: string;
  name: string;
  packages: {
    data: Package[];
  };
  plans: {
    data: Plan[];
  };
}

interface PricingSchemeResponse {
  data: PricingScheme[];
}

interface ProductResponse {
  data: Product[];
}

export default function PricingSchemeListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get('view') || 'packages';

  const { data: pricingSchemes, error: schemesError } = useSWR<PricingSchemeResponse>('/pricing/schemes', getFetcher);
  const { data: products, error: productsError } = useSWR<ProductResponse>('/catalog/products?limit=9999', getFetcher);

  const handleViewChange = (newView: string) => {
    setSearchParams({ view: newView });
  };

  if (schemesError || productsError) {
    return (
      <div className="p-10">
        <div className="text-center text-destructive">
          Error: Failed to load data
        </div>
      </div>
    );
  }

  if (!pricingSchemes || !products) {
    return (
      <div className="p-10">
        <div className="flex items-center justify-center">
          <Loader2 className="h-6 w-6 animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div className="p-10 space-y-6">
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <H1>Pricing Schemes</H1>
          <p className="text-sm text-muted-foreground">
            Manage your pricing schemes, packages, and plans
          </p>
        </div>
        <Button variant="outline" asChild>
          <Link to="/catalog/pricing/schemes/create">Add Scheme</Link>
        </Button>
      </div>

      <div className="flex items-center justify-between">
        <Tabs defaultValue={view} onValueChange={handleViewChange}>
          <TabsList>
            <TabsTrigger value="packages">Packages</TabsTrigger>
            <TabsTrigger value="products">Products</TabsTrigger>
            <TabsTrigger value="plans">Plans</TabsTrigger>
          </TabsList>
        </Tabs>

        {view === 'packages' && (
          <Button variant="outline" asChild>
            <Link to="/catalog/pricing/packages/create">Create Package</Link>
          </Button>
        )}
        {view === 'products' && (
          <Button variant="outline" asChild>
            <Link to="/catalog/products/create">Create Product</Link>
          </Button>
        )}
        {view === 'plans' && (
          <Button variant="outline" asChild>
            <Link to="/catalog/pricing/plans/create">Create Plan</Link>
          </Button>
        )}
      </div>

      {view === 'products' && (
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Product</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Family</TableHead>
                <TableHead>Published</TableHead>
                <TableHead>Updated</TableHead>
                <TableHead>Plans</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {products.data.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>
                    <Link 
                      to={`/catalog/products/${product.id}`}
                      className="font-medium text-primary hover:underline"
                    >
                      {product.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Badge variant={product.status === 'published' ? 'default' : 'secondary'}>
                      {product.status}
                    </Badge>
                  </TableCell>
                  <TableCell>{product.family}</TableCell>
                  <TableCell>{product.published_at ? formatDate(product.published_at) : '-'}</TableCell>
                  <TableCell>{formatDate(product.updated_at)}</TableCell>
                  <TableCell>{product.plans_count || '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}

      {["plans", "packages"].includes(view) && pricingSchemes.data.map((scheme) => (
        <div key={scheme.id} className="space-y-4">
          <div className="flex items-center justify-between">
            <Link 
              to={`/catalog/pricing/schemes/${scheme.id}`}
              className="text-lg font-semibold hover:underline"
            >
              {scheme.name}
            </Link>
          </div>

          <div className="rounded-md border">
            <Table>
              {view === 'packages' && (
                <>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Package</TableHead>
                      <TableHead>Plans</TableHead>
                      <TableHead>Currencies</TableHead>
                      <TableHead>Billing Intervals</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {scheme.packages.data.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4} className="text-center text-muted-foreground">
                          No packages found
                        </TableCell>
                      </TableRow>
                    ) : (
                      scheme.packages.data.map((pkg) => (
                        <TableRow key={pkg.id}>
                          <TableCell>
                            <Link 
                              to={`/catalog/pricing/packages/${pkg.id}`}
                              className="font-medium text-primary hover:underline"
                            >
                              {pkg.name}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {scheme.plans.data.filter(p => p.package?.id === pkg.id).length}
                          </TableCell>
                          <TableCell>
                            {pkg.currencies?.map(c => `${c.currency_code} ${c.currency_flag}`).join(', ')}
                          </TableCell>
                          <TableCell>
                            {pkg.intervals?.map(i => i.label).join(', ')}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </>
              )}

              {view === 'plans' && (
                <>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Plan</TableHead>
                      <TableHead>ID</TableHead>
                      <TableHead>Type</TableHead>
                      <TableHead>Status</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {scheme.plans.data.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4} className="text-center text-muted-foreground">
                          No plans found
                        </TableCell>
                      </TableRow>
                    ) : (
                      scheme.plans.data.map((plan) => (
                        <TableRow key={plan.id}>
                          <TableCell>
                            <Link 
                              to={`/catalog/pricing/plans/${plan.id}`}
                              className="font-medium text-primary hover:underline"
                            >
                              <div className="flex items-center justify-between">
                                <span>{plan.package?.name}</span>
                                <Badge variant="outline">
                                  {plan.currency_code} {plan.currency_flag} {plan.renew_interval}
                                </Badge>
                              </div>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <div className="flex items-center gap-2">
                              <code className="text-sm font-mono bg-muted px-1 py-0.5 rounded">
                                {plan.id}
                              </code>
                              <Button 
                                variant="ghost" 
                                size="sm"
                                className="h-6 w-6 p-0"
                                onClick={() => navigator.clipboard.writeText(plan.id)}
                              >
                                <Copy className="h-3 w-3" />
                              </Button>
                            </div>
                          </TableCell>
                          <TableCell>{plan.type}</TableCell>
                          <TableCell>
                            <Badge variant={plan.status === 'active' ? 'default' : 'secondary'}>
                              {plan.status}
                            </Badge>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </>
              )}
            </Table>
          </div>
        </div>
      ))}

      {!pricingSchemes.data.length && (
        <div className="text-center text-muted-foreground py-8">
          No pricing schemes found
        </div>
      )}
    </div>
  );
}
