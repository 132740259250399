import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { H1 } from "~/components/ui/typography";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import { Link } from "react-router-dom";
import { cn } from "~/lib/utils";
import { Loader2 } from "lucide-react";
import Pagination from "~/components/Pagination";

interface Signal {
  id: number;
  type: string;
  customer?: {
    id: string;
    email?: string;
  }
  amount: string;
  amount_raw: string;
  category: string;
  metadata: Record<string, any>;
  effective_at: string;
  created_at: string;
}

interface PaginationMeta {
  current_page: number;
  from: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
  links: {
    url: string | null;
    label: string;
    active: boolean;
  }[];
}

interface SignalResponse {
  data: Signal[];
  meta: PaginationMeta;
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
}

function formatAmount(amount: string, amount_raw: string) {
  const value = parseInt(amount_raw);
  if (value === 0) return amount;
  
  return `${value > 0 ? '+' : ''}${amount}`;
}

function SignalBadge({ type }: { type: string }) {
  switch (type.toLowerCase()) {
    case 'subscription':
      return <Badge variant="default">Subscription</Badge>;
    case 'charge':
      return <Badge variant="secondary">Charge</Badge>;
    case 'refund':
      return <Badge variant="destructive">Refund</Badge>;
    default:
      return <Badge variant="outline">{type}</Badge>;
  }
}

export default function SignalsListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page')) || 1;
  
  const { data, error, isLoading } = useSWR<SignalResponse>(
    `/account/signals?page=${currentPage}`,
    getFetcher
  );

  const handlePageChange = (page: number) => {
    setSearchParams({ page: page.toString() });
  };

  return (
    <div className="p-10 space-y-6">
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <H1>Signals</H1>
          <p className="text-sm text-muted-foreground">
            A list of all signals received from your integrations.
          </p>
        </div>
        <Button variant="outline" asChild>
          <Link to="/account/signals/docs" className="gap-2">
            Documentation
          </Link>
        </Button>
      </div>

      {error && (
        <div className="flex items-center justify-center p-6 text-destructive">
          Failed to load signals
        </div>
      )}
      
      {isLoading && (
        <div className="flex items-center justify-center p-6">
          <Loader2 className="h-6 w-6 animate-spin" />
        </div>
      )}
      
      {data && (
        <>
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>ID</TableHead>
                  <TableHead>Customer</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Category</TableHead>
                  <TableHead>Amount</TableHead>
                  <TableHead>Effective Date</TableHead>
                  <TableHead>Created Date</TableHead>
                </TableRow>
              </TableHeader>

              <TableBody>
                {data.data.map((signal) => (
                  <TableRow key={signal.id}>
                    <TableCell className="font-medium">{signal.id}</TableCell>
                    <TableCell>
                      {signal.customer && (
                        <Link 
                          to={`/customers/${signal.customer.id}`}
                          className="text-primary hover:underline"
                        >
                          {signal.customer.email || signal.customer.id}
                        </Link>
                      )}
                    </TableCell>
                    <TableCell>
                      <SignalBadge type={signal.type} />
                    </TableCell>
                    <TableCell>{signal.category}</TableCell>
                    <TableCell className={cn({
                      "text-emerald-600": parseInt(signal.amount_raw) > 0,
                      "text-destructive": parseInt(signal.amount_raw) < 0,
                      "text-muted-foreground": parseInt(signal.amount_raw) === 0,
                    })}>
                      {formatAmount(signal.amount, signal.amount_raw)}
                    </TableCell>
                    <TableCell>
                      {new Date(signal.effective_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {new Date(signal.created_at).toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <div className="mt-4">
            <Pagination
              resource={data}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  );
}