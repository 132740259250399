import { useParams } from "react-router-dom";
import WorkflowLayout from "./WorkflowLayout";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { useState } from "react";
import { H1 } from "~/components/ui/typography";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { Button } from "~/components/ui/button";
import { formatDistanceToNow } from "date-fns";

interface Event {
  id: string;
  type: string;
  collector_id: string;
  data: Record<string, any>;
  created_at: string;
}

interface EventsResponse {
  data: Event[];
  meta: {
    current_page: number;
    per_page: number;
    total: number;
  };
  links: {
    next: string | null;
    prev: string | null;
  };
}

function EventsTable({ events }: { events: Event[] }) {
  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Event Name</TableHead>
            <TableHead>Collector ID</TableHead>
            <TableHead>Data</TableHead>
            <TableHead>Occurred At</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {events.map((event) => (
            <TableRow key={event.id}>
              <TableCell className="font-medium">{event.type}</TableCell>
              <TableCell>{event.collector_id}</TableCell>
              <TableCell>
                <pre className="text-xs text-muted-foreground whitespace-pre-wrap">
                  {JSON.stringify(event.data, null, 2)}
                </pre>
              </TableCell>
              <TableCell className="text-muted-foreground">
                {formatDistanceToNow(new Date(event.created_at), { addSuffix: true })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function EventsPagination({ 
  data, 
  onPageChange, 
  currentPage 
}: { 
  data: EventsResponse; 
  onPageChange: (page: number) => void;
  currentPage: number;
}) {
  return (
    <div className="flex justify-between items-center mt-4">
      <Button 
        onClick={() => onPageChange(currentPage - 1)} 
        disabled={!data.links.prev}
        variant="outline"
      >
        Previous
      </Button>
      <span className="text-sm text-muted-foreground">
        Page {currentPage}
      </span>
      <Button 
        onClick={() => onPageChange(currentPage + 1)} 
        disabled={!data.links.next}
        variant="outline"
      >
        Next
      </Button>
    </div>
  );
}

export default function WorkflowEventsPage() {
  const params = useParams();
  const [page, setPage] = useState(1);

  const { data: workflow, error: workflowError } = useSWR(
    `/intel/flows/${params.id}`, 
    getFetcher
  );

  const { data: eventsData, error: eventsError, isLoading: eventsLoading } = useSWR<EventsResponse>(
    `/convert/workflows/${params.id}/events?page=${page}`,
    getFetcher
  );

  if (workflowError || eventsError) {
    return (
      <WorkflowLayout workflow={workflow}>
        <div className="p-10">
          <div className="text-destructive">Failed to load events</div>
        </div>
      </WorkflowLayout>
    );
  }

  if (!workflow || !eventsData || eventsLoading) {
    return (
      <WorkflowLayout workflow={workflow}>
        <div className="p-10">
          <div className="text-muted-foreground">Loading...</div>
        </div>
      </WorkflowLayout>
    );
  }

  return (
    <WorkflowLayout workflow={workflow}>
      <div className="space-y-6 pt-8">
        
        <EventsTable events={eventsData.data} />
        
        <EventsPagination
          data={eventsData}
          onPageChange={setPage}
          currentPage={page}
        />
      </div>
    </WorkflowLayout>
  );
}