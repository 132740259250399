import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "~/components/ui/button";
import { H1, H3 } from "~/components/ui/typography";
import { Tabs, TabsList, TabsTrigger } from "~/components/ui/tabs";

type WorkflowLayoutProps = {
  workflow: {
    id: string;
    name: string;
  };
  children: React.ReactNode;
};

export default function WorkflowLayout({ workflow, children }: WorkflowLayoutProps) {
  const location = useLocation();
  if (!workflow) {
    return <div>Workflow not found</div>;
  }

  // Determine which tab is active based on the current path
  const getTabValue = () => {
    const path = location.pathname;
    if (path === `/convert/workflows/${workflow.id}`) return "funnel";
    if (path === `/convert/workflows/${workflow.id}/revenue`) return "revenue";
    if (path === `/convert/workflows/${workflow.id}/paywalls`) return "build";
    if (path === `/convert/workflows/${workflow.id}/activity`) return "activity";
    if (path === `/convert/workflows/${workflow.id}/events`) return "events";
    return "funnel"; // Default tab
  };

  return (
    <div className="min-h-screen p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">{workflow.name}</h1>
        <div className="flex gap-2">
        <Button variant="ghost" asChild>
            <Link to={`/account/activities?flow=${workflow.id}`}>Activities</Link>
          </Button>
          <Button variant="default" asChild>
            <Link to={`/convert/workflows/${workflow.id}/edit`}>Edit</Link>
          </Button>
        </div>
      </div>

      <Tabs value={getTabValue()} className="w-full">
        <TabsList className="">
          <TabsTrigger 
            value="funnel" 
            
            asChild
          >
            <Link to={`/convert/workflows/${workflow.id}`}>Funnel</Link>
          </TabsTrigger>
          <TabsTrigger 
            value="revenue" 
            asChild
          >
            <Link to={`/convert/workflows/${workflow.id}/revenue`}>Revenue</Link>
          </TabsTrigger>
          <TabsTrigger 
            value="build" 
            asChild
          >
            <Link to={`/convert/workflows/${workflow.id}/paywalls`}>Build</Link>
          </TabsTrigger>
          <TabsTrigger 
            value="activity" 
            asChild
          >
            <Link to={`/convert/workflows/${workflow.id}/activity`}>Activity</Link>
          </TabsTrigger>
          <TabsTrigger 
            value="events" 
            asChild
          >
            <Link to={`/convert/workflows/${workflow.id}/events`}>Events</Link>
          </TabsTrigger>
        </TabsList>
      </Tabs>

      <div>
        {children}
      </div>
    </div>
  );
}
