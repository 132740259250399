import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import React from "react";
import { Link } from "react-router-dom";
import { H1 } from "~/components/ui/typography";
import { Button } from "~/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { Badge } from "~/components/ui/badge";
import OrganizationLayout from "~/layouts/OrganizationLayout";

interface Metric {
  id: string;
  name: string;
  event_name: string;
  aggregation: string;
  type: string;
  status: 'active' | 'inactive';
}

interface MetricsResponse {
  data: Metric[];
}

export default function MetricsListPage() {
  const { data: metrics, error } = useSWR<MetricsResponse>('/usage/metrics', getFetcher);

  if (error) return <div>Failed to load metrics</div>;
  if (!metrics) return <div>Loading...</div>;

  return (
    <OrganizationLayout>
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <div>
            <H1>Metrics</H1>
            <p className="text-muted-foreground mt-2">
              Manage and monitor your usage metrics
            </p>
          </div>
          <Button asChild>
            <Link to="/catalog/metrics/create">Create Metric</Link>
          </Button>
        </div>

        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Event Name</TableHead>
                <TableHead>Aggregation</TableHead>
                <TableHead>Type</TableHead>
                <TableHead>Status</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {metrics.data.map((metric) => (
                <TableRow key={metric.id}>
                  <TableCell>
                    <Link
                      to={`/catalog/metrics/${metric.id}/edit`}
                      className="font-medium text-primary hover:underline"
                    >
                      {metric.name}
                    </Link>
                  </TableCell>
                  <TableCell>{metric.event_name}</TableCell>
                  <TableCell>{metric.aggregation}</TableCell>
                  <TableCell>{metric.type}</TableCell>
                  <TableCell>
                    <Badge
                      variant={metric.status === 'active' ? 'default' : 'secondary'}
                    >
                      {metric.status}
                    </Badge>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </OrganizationLayout>
  );
}
