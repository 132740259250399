import {useParams} from "react-router";
import useSWR from "swr";
import apiClient, {getFetcher} from "~/lib/apiClient";
import WorkflowLayout from "~/pages/convert/workflows/WorkflowLayout";
import {Text, Badge, Table} from "@radix-ui/themes";
import {Link} from "react-router-dom";
import {get} from "lodash";
import React, { useEffect, useMemo, useState, useRef } from "react";
import {ArrowDownRightIcon, EyeIcon} from "@heroicons/react/16/solid";
import classNames from "classnames";
import AnchorButton from "~/components/AnchorButton";
import { ArrowRightIcon, BadgeDollarSignIcon, CheckCircle, CircleDollarSignIcon, MousePointerClickIcon, ShoppingCartIcon, TestTubeIcon, ViewIcon, CloudLightning, TextCursorIcon, CodeIcon, CloudLightningIcon } from "lucide-react";
import { PlusIcon } from "lucide-react";
import { ArrowUpRightIcon, EditIcon, ArrowUpRight, AlertTriangle } from "lucide-react";
import { Input } from "~/components/ui/input";
import JSONInput from "~/components/form/JSONInput";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";
import update from "immutability-helper";
import { toast } from "react-toastify";
import { Button } from "~/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter, DialogClose, DialogTrigger } from "~/components/ui/dialog";
import { Switch } from "~/components/ui/switch";
import { Card } from "~/components/ui/card";

interface Workflow {
  id: string;
  name: string;
  triggers?: Array<{
    listen: string;
    qualifier?: string;
    props?: Record<string, any>;
    event?: string;
  }>;
}

interface Offer {
  id: string;
  name: string;
}

interface PricingScheme {
  id: string;
  name: string;
}

interface ScenarioData {
  conditions: Array<Record<string, any>>;
  offer: string | null;
  scheme: string | null;
  display_name?: string;
}

interface Collection<T> {
  data: T[];
  winner?: string;
}

interface ScenarioDialogProps {
  workflow: Workflow;
  scenario: any;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (data?: any) => void;
}

interface JSONInputProps {
  value: Array<Record<string, any>> | Record<string, any>;
  onChange: (value: Array<Record<string, any>> | Record<string, any>) => void;
  errors?: string[];
}

interface Rule {
  type: 'percentage';
  value: number;
}

interface RolloutData {
  rules: Rule[];
  is_active: boolean;
}

interface RolloutItemProps {
  rollout: {
    id: string;
    client: {
      id: string;
      name: string;
    };
    rules: Rule[];
    is_active: boolean;
    percentage: number;
    participations_count: number;
  };
  workflow: Workflow;
  mutate: () => void;
}

const qualifierMap = {
  findButtonByText: 'button containing'
};

const eventMap = {
  click: 'is clicked',
};

const listenMap = {
  attachment: 'When ',
  custom: 'When custom event'
};

function ScenarioDialog({ workflow, scenario, isOpen, onClose, onSuccess }: ScenarioDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, any>>({});
  const [data, setData] = useState<ScenarioData>({
    conditions: scenario?.conditions || [],
    offer: scenario?.offer || null,
    scheme: scenario?.scheme?.id || scenario?.scheme || null,
    display_name: scenario?.display_name || '',
  });

  const { data: offers } = useSWR<{ data: Offer[] }>('/store/offers', getFetcher);
  const { data: schemes } = useSWR<{ data: PricingScheme[] }>('/pricing/schemes', getFetcher);
  const { mutate: mutateScenarios } = useSWR(
    `/intel/flows/${workflow.id}/scenarios`,
    getFetcher
  );
  const { mutate: mutateWorkflow } = useSWR(
    `/intel/flows/${workflow.id}`,
    getFetcher
  );

  // Reset form when scenario changes
  useEffect(() => {
    setData({
      conditions: scenario?.conditions || [],
      offer: scenario?.offer || null,
      scheme: scenario?.scheme?.id || scenario?.scheme || null,
      display_name: scenario?.display_name || '',
    });
  }, [scenario]);

  const handleDelete = async () => {
    if (!confirm('Are you sure you want to delete this scenario?')) return;
    
    setIsLoading(true);
    try {
      await apiClient.delete(`/intel/scenarios/${scenario.id}`);
      toast.success("Paywall deleted successfully");
      // Mutate both the scenarios list and workflow data
      await Promise.all([
        mutateScenarios(),
        mutateWorkflow()
      ]);
      onSuccess();
      onClose();
    } catch (err) {
      console.error("Failed to delete paywall:", err);
      toast.error("Failed to delete paywall");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    try {
      let response;
      const submitData = {
        ...data,
        offer: data.offer || '',
        scheme: data.scheme || ''
      };

      if (scenario) {
        response = await apiClient.put(`/intel/scenarios/${scenario.id}`, submitData);
        toast.success("Paywall updated successfully");
      } else {
        response = await apiClient.post(`/intel/flows/${workflow.id}/scenarios`, submitData);
        toast.success("Paywall created successfully");
      }

      onSuccess(response.data);
      onClose();
      await mutateScenarios();
    } catch (err) {
      console.error("Failed to save paywall:", err);
      setErrors(err.response?.data?.errors || {});
      toast.error(`Failed to ${scenario ? 'update' : 'create'} paywall`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{scenario ? 'Edit' : 'Create New'} Paywall</DialogTitle>
          <DialogDescription>
            {scenario ? 'Modify the offer and conditions for this paywall.' : 'Select an offer to present and define when it should be shown.'}
          </DialogDescription>
        </DialogHeader>

        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div className="space-y-2">
              <label className="text-sm font-medium">Display Name</label>
              <Input 
                value={data.display_name || ''} 
                onChange={(e) => setData({ ...data, display_name: e.target.value })}
                placeholder="Enter a name for this paywall"
                className={errors?.display_name ? 'border-destructive' : ''}
              />
              {errors?.display_name && (
                <p className="text-sm text-destructive">{errors.display_name}</p>
              )}
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Select Offer</label>
              <Select 
                value={data.offer || 'none'} 
                onValueChange={(value) => setData({ ...data, offer: value === 'none' ? '' : value })}
              >
                <SelectTrigger className={errors?.offer ? 'border-destructive' : ''}>
                  <SelectValue placeholder="Choose an offer..." />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="none">Choose an offer...</SelectItem>
                  {offers?.data?.map(offer => (
                    <SelectItem key={offer.id} value={offer.id}>
                      {offer.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {errors?.offer && (
                <p className="text-sm text-destructive">{errors.offer}</p>
              )}
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Pricing Scheme</label>
              <Select 
                value={data.scheme || 'none'} 
                onValueChange={(value) => setData({ ...data, scheme: value === 'none' ? '' : value })}
              >
                <SelectTrigger className={errors?.scheme ? 'border-destructive' : ''}>
                  <SelectValue placeholder="Choose a pricing scheme..." />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="none">Choose a pricing scheme...</SelectItem>
                  {schemes?.data?.map(scheme => (
                    <SelectItem key={scheme.id} value={scheme.id}>
                      {scheme.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {errors?.scheme && (
                <p className="text-sm text-destructive">{errors.scheme}</p>
              )}
            </div>

            <div>
              <Text as="div" className="text-sm font-medium mb-1.5">Conditions</Text>
              <JSONInput
                value={data.conditions}
                onChange={(value: Array<Record<string, any>>) => setData({ ...data, conditions: value })}
                errors={errors.conditions}
              />
              <Text as="div" className="text-sm text-gray-500 mt-1">
                Define when this paywall should be shown using JSON conditions
              </Text>
            </div>
          </div>

          <DialogFooter className="mt-6">
            {scenario && (
              <Button 
                type="button" 
                variant="destructive" 
                disabled={isLoading}
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
            <div className="flex-1" />
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? (scenario ? "Saving..." : "Creating...") : (scenario ? "Save Changes" : "Create Paywall")}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default function WorkflowPaywallsPage() {
  const params = useParams();
  const [dialogState, setDialogState] = useState({ isOpen: false, scenario: null });
  const {data: workflow, error, mutate: mutateWorkflow} = useSWR<Workflow>(
    `/intel/flows/${params.id}`,
    getFetcher
  );
  const { mutate: mutateScenariosList } = useSWR(
    `/intel/flows/${params.id}/scenarios`,
    getFetcher
  );

  if (!workflow) return null;

  return (
    <WorkflowLayout workflow={workflow}>
      <div className="pt-8">
        <PaywallList 
          workflow={workflow} 
          onCreateClick={() => setDialogState({ isOpen: true, scenario: null })}
          onEditClick={(scenario) => setDialogState({ isOpen: true, scenario })}
        />

        <ScenarioDialog
          workflow={workflow}
          scenario={dialogState.scenario}
          isOpen={dialogState.isOpen}
          onClose={() => setDialogState({ isOpen: false, scenario: null })}
          onSuccess={async () => {
            // First close the dialog
            setDialogState({ isOpen: false, scenario: null });
            // Then refresh the data
            await Promise.all([
              mutateWorkflow(),
              mutateScenariosList()
            ]);
          }}
        />

        <div className="py-6">
          <h2 className="text-2xl font-semibold mb-4">Triggers</h2>
          {workflow.triggers && workflow.triggers.length > 0 ? (
            <div className="">
              {workflow.triggers.map((trigger, index) => (
                <React.Fragment key={index}>
                  <TriggerCard trigger={trigger} index={index} />
                  {index < workflow.triggers.length - 1 && (
                    <div className="mb-2 font-bold text-sm pl-11 text-gray-900">OR</div>
                  )}
                </React.Fragment>
              ))}
            </div>
          ) : (
            <div className="text-center py-8 bg-gray-50 rounded-lg">
              <CloudLightningIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">No triggers defined</h3>
              <p className="mt-1 text-sm text-gray-500">
                Add triggers to determine when this workflow should be activated.
              </p>
            </div>
          )}
        </div>
      </div>
    </WorkflowLayout>
  );
}

function EmptyState({ title, description, actionText, onAction }) {
  return (
    <div className="text-center py-16 rounded-lg">
      <ShoppingCartIcon className="mx-auto h-12 w-12 text-gray-700" />
      <h3 className="mt-4 text-lg font-semibold text-gray-900">{title}</h3>
      <p className="mt-2 text-sm text-gray-800 max-w-sm mx-auto">
        {description}
      </p>
      {actionText && (
        <div className="mt-6">
          <Button onClick={onAction}>
            <PlusIcon className="h-4 w-4 mr-2" />
            {actionText}
          </Button>
        </div>
      )}
    </div>
  );
}

export function PaywallList({ workflow, onCreateClick, onEditClick }) {
  const {
    data: collection,
    error
  } = useSWR<Collection<any>>(
    `/intel/flows/${workflow.id}/scenarios`, 
    getFetcher,
  );

  if (error) return <div>Failed to load</div>;
  if (!collection) return <div>Loading...</div>;

  return (
    <div className="">
      <div className="flex gap-2 items-center pt-2 mb-2">
        <Button onClick={onCreateClick}>
          <PlusIcon className="h-4 w-4" />
          Create New
        </Button>
      </div>

      {collection.data.length === 0 ? (
        <EmptyState
          title="No Paywalls Yet"
          description="Create your first paywall to start monetizing your workflow. Paywalls help you control access to premium content or features."
          actionText="Create Your First Paywall"
          onAction={onCreateClick}
        />
      ) : (
        <>
          <div className="grid grid-cols-11 font-bold text-sm bg-gray-50 rounded-lg border rounded-b-none py-3">
            <div className="col-span-3 pl-6">Paywall</div>
            <div className="col-span-2">Unique Views</div>
            <div className="col-span-2">Conversions</div>
            <div className="col-span-2">Total Revenue</div>
            <div className="col-span-2">Actions</div>
          </div>

          <div className="">
            {collection.data.map(paywall => (
              <PaywallStatsComponent
                key={paywall.id}
                paywall={paywall}
                workflow={workflow}
                isWinner={paywall.id === collection.winner}
                onEditClick={() => onEditClick(paywall)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

interface PaywallStats {
  unique_views: { value: number; diff_relative: number; diff_percentage: number };
  conversions_count: { value: number; diff_relative: number; diff_percentage: number };
  revenue_sum: { value: number };
}

interface Paywall {
  id: string;
  stats: PaywallStats;
  display_name: string;
  offer?: string;
}

interface PaywallStatsComponentProps {
  paywall: Paywall;
  workflow: Workflow;
  isWinner: boolean;
  onEditClick: (paywall: Paywall) => void;
}

function PaywallStatsComponent({ paywall, workflow, isWinner, onEditClick }: PaywallStatsComponentProps) {
  const { data: offers } = useSWR<{ data: Offer[] }>('/store/offers', getFetcher);
  const [dialogState, setDialogState] = useState({ isOpen: false });

  return (
    <>
      <div className="border border-gray-200/50 border-t-0 overflow-hidden">
        <div className="grid grid-cols-11">
          <div className="px-5 py-2 col-span-3">
            <h3 className="text-lg font-semibold">{paywall.display_name}</h3>
            <Link to={`/convert/paywalls/${paywall.id}/view`} className="text-link flex items-center">
              View
              <ArrowRightIcon className="w-3 h-3 ml-1" />
            </Link>
            {paywall.offer ? (
              <Link 
                to={`/store/offers/${paywall.offer}`} 
                className="text-xs text-primary hover:underline mt-1 flex items-center gap-1"
              >
                <span>View offer</span>
                <ArrowUpRight className="w-3 h-3" />
              </Link>
            ) : (
              <span className="text-xs text-red-500 mt-1 flex items-center gap-1">
                <span>No offer linked</span>
                <AlertTriangle className="h-3 w-3" />
              </span>
            )}
          </div>

          <StatColumn
            title="Unique Views"
            value={paywall.stats.unique_views.value}
            change={paywall.stats.unique_views.diff_relative}
            percentage={paywall.stats.unique_views.diff_percentage}
          />

          <StatColumn
            title="Conversions"
            value={paywall.stats.conversions_count.value}
            change={paywall.stats.conversions_count.diff_relative}
            percentage={paywall.stats.conversions_count.diff_percentage}
          />

          <StatColumn
            title="Total Revenue"
            value={paywall.stats.revenue_sum.value}
            change={null}
            percentage={null}
          />

          <div className="flex gap-2 items-start py-6 col-span-2">
            <Button 
              variant="outline" 
              size="sm" 
              onClick={() => setDialogState({ isOpen: true })}
            >
              <EditIcon className="w-3 h-3 mr-1" /> Edit
            </Button>
            <Button variant="outline" size="sm" asChild>
              <Link to={`/convert/paywalls/${paywall.id}/sandbox`}>
                <TestTubeIcon className="w-3 h-3 mr-1" /> Sandbox
              </Link>
            </Button>
          </div>
        </div>
      </div>

      <ScenarioDialog
        workflow={workflow}
        scenario={paywall}
        isOpen={dialogState.isOpen}
        onClose={() => setDialogState({ isOpen: false })}
        onSuccess={() => {
          setDialogState({ isOpen: false });
          // Remove the call to onEditClick here to prevent reopening the dialog
        }}
      />
    </>
  );
}

interface StatColumnProps {
  title: string;
  value: number;
  change: number | null;
  percentage: number | null;
}

function StatColumn({ title, value, change, percentage }: StatColumnProps) {
  const isPositive = percentage && percentage > 0;
  const isNegative = percentage && percentage < 0;

  return (
    <div className="space-y-X2 col-span-2 flex flex-col justify-center">
      <div>
        <dd className="mt-1 text-3xl font-semibold">{value}</dd>
      </div>
      {percentage === null && <div className="text-gray-700 text-xs">All Time</div>}
      {percentage !== null && (
        <div className="flex items-center text-xs gap-2">
          {percentage !== 0 && (
            <div
              className={`flex items-center ${
                isPositive ? 'text-green-600' : isNegative ? 'text-red-600' : 'text-gray-600'
              }`}
            >
              {isPositive && <ArrowUpRightIcon className="w-3 h-3 mr-1" />}
              {isNegative && <ArrowDownRightIcon className="w-3 h-3 mr-1" />}
              {percentage !== 0 ? `${Math.abs(percentage)}%` : ''}
            </div>
          )}
          <div className="text-gray-700">
            {change > 0 ? '+' : ''}{change} this week
          </div>
        </div>
      )}
    </div>
  );
}

function TriggerCard({ trigger, index }: { trigger: Workflow['triggers'][0], index: number }) {
  const getTriggerIcon = (listen: string) => {
    switch (listen) {
      case 'attachment':
        return <TextCursorIcon className="h-5 w-5 text-blue-500" />;
      case 'custom':
        return <CodeIcon className="h-5 w-5 text-purple-500" />;
      default:
        return <CloudLightning className="h-5 w-5 text-yellow-500" />;
    }
  };

  return (
    <Card className="mb-2">
      <div className="flex items-center gap-3 p-4">
        {getTriggerIcon(trigger.listen)}
        <Text weight="bold">Trigger {index + 1}</Text>
        <div className="flex gap-2 flex-wrap">
          <Badge color="blue">
            <Text>{listenMap[trigger.listen] || trigger.listen}</Text>
          </Badge>
          
          {trigger.qualifier && (
            <Badge color="green">
              <Text>{qualifierMap[trigger.qualifier] || trigger.qualifier}</Text>
            </Badge>
          )}
          {trigger.props && Object.keys(trigger.props).length > 0 && (
            <Badge color="purple">
              <Text>{Object.entries(trigger.props).map(([key, value]) => `${key}: "${value}"`).join(', ')}</Text>
            </Badge>
          )}
          {trigger.event && (
            <Badge color="orange">
              <Text>{eventMap[trigger.event] || trigger.event}</Text>
            </Badge> 
          )}
        </div>
      </div>
    </Card>
  );
}

