import {useParams} from "react-router";
import useSWR, { mutate } from "swr";
import apiClient, { getFetcher } from "~/lib/apiClient";
import Debug from "~/components/Debug";
import PageHeader from "~/components/PageHeader";
import {Link} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import React, { useState } from "react";
import { avatarLetters } from "./CustomersListPage";
import { formatDateTime } from "~/utils/dateTime";
import { ExternalLinkIcon, CreditCard, Clock, Building2, Mail, Phone, Code, RefreshCw } from "lucide-react";
import { H1 } from "~/components/ui/typography";
import { Card, CardContent, CardHeader } from "~/components/ui/card";
import { AvatarFallback, AvatarImage } from "~/components/ui/avatar";
import { TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs";
import {
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableHeader,  
} from "~/components/ui/table";
import {
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog";
import {
  ChevronLeft,
  ExternalLink,
  Loader2,
} from "lucide-react";
import { cn } from "~/lib/utils";
import { Button } from "~/components/ui/button";
import { Badge } from "~/components/ui/badge";
import { Avatar } from "~/components/ui/avatar";
import { Tabs } from "~/components/ui/tabs";
import {
  Dialog,
} from "~/components/ui/dialog";

interface Customer {
  id: string;
  name?: string;
  email: string;
  phone?: string;
  created_at: string;
  reference_id?: string;
  twin?: {
    connector_link?: string;
  };
  hostname?: string;
  subscriptions?: any[];
}

interface UsagePeriod {
  period_start_at: string;
  period_end_at: string;
  amount: number;
  currency: string;
  fees: Array<{
    amount: string;
    description: string | null;
    usage: number;
    charge?: {
      name: string;
      currency: string;
      type: string;
      amount_minimum_spend: string;
      minimum_billable_usage: string | null;
      amount: string;
    };
    metric?: {
      id: string;
      event_name: string;
      aggregation: string;
      type: string;
    };
  }>;
}

interface PaginatedResponse<T> {
  data: T[];
  meta: {
    current_page: number;
    per_page: number;
    total?: number;
    last_page: number;
  };
  links: {
    next?: string;
    prev?: string;
  };
}

interface CustomerEvent {
  id: string;
  event_name: string;
  unique_id: string;
  properties: Record<string, any>;
  created_at: string;
  metadata?: Record<string, any>;
}

function formatCurrency(amount: number | string, currency: string) {
  const numericAmount = typeof amount === 'string' ? parseInt(amount, 10) : amount;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numericAmount / 100);
}

function formatDateOnly(dateStr: string) {
  return new Date(dateStr).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
}

function DateWithTooltip({ date }: { date: string }) {
  return (
    <div className="group relative inline-block">
      <span className="cursor-help border-b border-dotted border-gray-300">
        {formatDateOnly(date)}
      </span>
      <div className="pointer-events-none absolute bottom-full left-1/2 mb-2 -translate-x-1/2 opacity-0 transition-opacity group-hover:opacity-100">
        <div className="flex items-center gap-2 rounded-md bg-secondary px-3 py-1.5 text-xs text-secondary-foreground">
          <Clock className="h-3 w-3" />
          <span>{formatDateTime(date)}</span>
        </div>
      </div>
    </div>
  );
}

function SubscriptionsList({ subscriptions = [] }: { subscriptions: any[] }) {
  if (subscriptions.length === 0) {
    return (
      <div className="text-center py-6 text-muted-foreground">
        No active subscriptions
      </div>
    );
  }

  return (
    <div className="relative w-full overflow-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Plan</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Currency</TableHead>
            <TableHead>Renewal</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {subscriptions.map((subscription) => (
            <TableRow key={subscription.id || subscription.plan?.id}>
              <TableCell>
                <div className="flex items-center gap-2">
                  <Link 
                    to={`/catalog/pricing/plans/${subscription.plan?.id}`}
                    className="text-primary hover:underline"
                  >
                    {subscription.plan?.name || 'Unnamed Plan'}
                  </Link>
                  <Badge variant="outline" className="text-xs">
                    x{subscription.quantity}
                  </Badge>
                </div>
              </TableCell>
              <TableCell>
                <Badge variant="outline">
                  {subscription.current_state}
                </Badge>
              </TableCell>
              <TableCell>{subscription.plan?.currency_code || '-'}</TableCell>
              <TableCell>{subscription.plan?.renew_interval || '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function JsonViewDialog({ data, open, onOpenChange }: { data: any, open: boolean, onOpenChange: (open: boolean) => void }) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Raw Data</DialogTitle>
        </DialogHeader>
        <div className="mt-4 max-h-[500px] overflow-auto rounded-lg bg-secondary p-4">
          <pre className="text-xs">
            {JSON.stringify(data, null, 2)}
          </pre>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function UsageDetails({ usage }: { usage: UsagePeriod }) {
  const [showJson, setShowJson] = useState(false);

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <div className="space-y-1">
          <h3 className="text-sm font-medium">Current Period Usage</h3>
          <div className="flex items-center gap-2 text-sm text-muted-foreground">
            <DateWithTooltip date={usage.period_start_at} />
            <span>–</span>
            <DateWithTooltip date={usage.period_end_at} />
          </div>
        </div>
        <div className="text-right">
          <div className="text-sm text-muted-foreground">Current Amount</div>
          <div className={cn("text-2xl font-bold", usage.amount > 0 && "text-emerald-600")}>
            {formatCurrency(usage.amount, usage.currency)}
          </div>
        </div>
      </CardHeader>
      <CardContent>
        {usage.fees.length > 0 ? (
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h4 className="text-sm font-medium">Usage Breakdown</h4>
              <Button variant="ghost" size="sm" onClick={() => setShowJson(true)}>
                <Code className="mr-2 h-4 w-4" />
                View JSON
              </Button>
            </div>
            <div className="relative w-full overflow-auto">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Description</TableHead>
                    <TableHead>Usage</TableHead>
                    <TableHead>Amount</TableHead>
                    <TableHead>Metric</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {usage.fees.map((fee, index) => (
                    <TableRow key={index}>
                      <TableCell className="font-medium">
                        {fee.description || fee.charge?.name || 'N/A'}
                      </TableCell>
                      <TableCell>
                        <Badge variant="outline">{fee.usage}</Badge>
                      </TableCell>
                      <TableCell>
                        {formatCurrency(fee.amount, fee.charge?.currency || usage.currency)}
                      </TableCell>
                      <TableCell>
                        {fee.metric && (
                          <Badge variant="outline">
                            {fee.metric.event_name} ({fee.metric.aggregation})
                          </Badge>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        ) : (
          <div className="text-center py-6 text-muted-foreground">
            No usage data for this period
          </div>
        )}
      </CardContent>
      <JsonViewDialog data={usage} open={showJson} onOpenChange={setShowJson} />
    </Card>
  );
}

function CustomerCard({ customer }: { customer: Customer }) {
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncResult, setSyncResult] = useState<{ type: 'success' | 'error', message: string } | null>(null);

  const handleSync = async () => {
    setIsSyncing(true);
    setSyncResult(null);
    try {
      await apiClient.patch(`/customers/${customer.id}`, {});
      await mutate(`/customers/${customer.id}`);
      setSyncResult({ type: 'success', message: 'Customer synced successfully' });
    } catch (error: any) {
      setSyncResult({ 
        type: 'error', 
        message: error?.response?.data?.message || 'Failed to sync customer'
      });
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <Card>
      <CardContent className="pt-6">
        <div className="space-y-6">
          <div className="flex items-start justify-between">
            <div className="flex gap-4">
              <Avatar className="h-16 w-16">
                <AvatarImage
                  src={customer.hostname ? `https://logo-service.plandalf.dev/api/logo?url=${customer.hostname}` : undefined}
                  alt={avatarLetters(customer)}
                />
                <AvatarFallback>{avatarLetters(customer)}</AvatarFallback>
              </Avatar>
              <div>
                <h2 className="text-2xl font-bold">{customer.name || customer.email}</h2>
                <p className="text-sm text-muted-foreground">
                  Customer since {formatDateTime(customer.created_at)}
                </p>
              </div>
            </div>
            <Button 
              variant="outline" 
              size="sm"
              onClick={handleSync}
              disabled={isSyncing}
            >
              <RefreshCw className={cn("mr-2 h-4 w-4", isSyncing && "animate-spin")} />
              Sync
            </Button>
          </div>

          <div className="space-y-4">
            <div className="flex items-center gap-2 text-muted-foreground">
              <Building2 className="h-4 w-4" />
              <span>{customer.name || '-'}</span>
            </div>

            <div className="flex items-center gap-2 text-muted-foreground">
              <Mail className="h-4 w-4" />
              <span>{customer.email}</span>
            </div>

            <div className="flex items-center gap-2 text-muted-foreground">
              <Phone className="h-4 w-4" />
              <span>{customer.phone || "-"}</span>
            </div>

            {customer.twin?.connector_link && (
              <div className="flex items-center gap-2 text-muted-foreground">
                <CreditCard className="h-4 w-4" />
                <a 
                  href={customer.twin.connector_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-1 text-primary hover:underline"
                >
                  {customer.reference_id}
                  <ExternalLink className="h-4 w-4" />
                </a>
              </div>
            )}
          </div>
        </div>
      </CardContent>

      {syncResult && (
        <div className={cn(
          "fixed bottom-4 right-4 p-4 rounded-lg shadow-lg z-50",
          syncResult.type === 'success' ? "bg-emerald-50 border border-emerald-200" : "bg-destructive/10 border border-destructive/20"
        )}>
          <div className={cn(
            "font-medium",
            syncResult.type === 'success' ? "text-emerald-900" : "text-destructive"
          )}>
            {syncResult.type === 'success' ? 'Success' : 'Error'}
          </div>
          <div className={cn(
            syncResult.type === 'success' ? "text-emerald-700" : "text-destructive"
          )}>
            {syncResult.message}
          </div>
        </div>
      )}
    </Card>
  );
}

export default function CustomerDetailPage() {
  const {customerId} = useParams();

  const { data: customer, error } = useSWR<Customer>(`/customers/${customerId}`, getFetcher);
  const { data: customerEvents, error: customerEventsError } = useSWR<PaginatedResponse<CustomerEvent>>(`/usage/events?customer=${customerId}`, getFetcher);
  const { data: usage, error: usageError } = useSWR<UsagePeriod>(`/customers/${customerId}/usage`, getFetcher);

  if (error) {
    return (
      <div className="p-10">
        <div className="text-center text-destructive">
          Error: {error.message || 'Failed to load customer'}
        </div>
      </div>
    );
  }

  if (!customer) {
    return (
      <div className="p-10">
        <div className="flex items-center justify-center">
          <Loader2 className="h-6 w-6 animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-secondary/10">
      <div className="p-10 space-y-6">
        <div className="flex items-center gap-4">
          <Button variant="ghost" size="sm" asChild>
            <Link to="/customers">
              <ChevronLeft className="h-4 w-4" />
              Back to Customers
            </Link>
          </Button>
          <div>
            <H1>Customer Details</H1>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-1">
            <CustomerCard customer={customer} />
          </div>

          <div className="col-span-2 space-y-6">
            <Tabs defaultValue="usage">
              <TabsList>
                <TabsTrigger value="usage">Usage & Billing</TabsTrigger>
                <TabsTrigger value="subscriptions">Subscriptions</TabsTrigger>
              </TabsList>
              <TabsContent value="usage" className="space-y-6">
                {usage && <UsageDetails usage={usage} />}
                {usageError && (
                  <div className="text-center text-destructive">
                    Error: {usageError.message || 'Failed to load usage data'}
                  </div>
                )}
              </TabsContent>
              <TabsContent value="subscriptions">
                <Card>
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <h3 className="text-lg font-semibold">Subscriptions</h3>
                      <Badge variant="secondary">
                        {customer.subscriptions?.length || 0} Active
                      </Badge>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <SubscriptionsList subscriptions={customer.subscriptions} />
                  </CardContent>
                </Card>
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
